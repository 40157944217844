import React from "react";

const DashboardTopCard = ({ dashboardData }) => {
    return (
        <>
            <div className="row mt-5">
                <div className="col-md-3">
                    <div className="card widget-card-1">
                        <div className="card-block-small status-info">
                            <i className="ti-user bg-ff9b44 card1-icon"></i>
                            <h6>Total Users</h6>
                            <h4>{dashboardData.length === 0 ? 0 : dashboardData[0][0]?.totalUsers}</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card widget-card-1">
                        <div className="card-block-small status-info">
                            <i className="fas fa-hotel bg-ff9b44 card1-icon"></i>
                            <h6>Total Property</h6>
                            <h4>{dashboardData.length === 0 ? 0 : dashboardData[1][0]?.totalProperties}</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card widget-card-1">
                        <div className="card-block-small status-info">
                            <i className="ti-trash bg-ff9b44 card1-icon"></i>
                            <h6>Total Dustbins</h6>
                            <h4>{dashboardData.length === 0 ? 0 : dashboardData[2][0]?.totalPlacedDustbin}</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card widget-card-1">
                        <div className="card-block-small status-info">
                            <i className="ti-truck bg-ff9b44 card1-icon"></i>
                            <h6>Total Pickups</h6>
                            <h4>{dashboardData.length === 0 ? 0 : dashboardData[3][0]?.totalPickups}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardTopCard;
import React from "react";
import { Chart as ChartJS, registerables } from "chart.js";
import { Pie } from "react-chartjs-2";
ChartJS.register(...registerables);

const DashboardMiddlePieChart = ({ dashboardData }) => {

    const graph = {
        labels: ["Pending", "Success", "Failed"],
        datasets: [
            {
                data: [dashboardData.length === 0 ? 0 : dashboardData[7][0]?.paymentPending, dashboardData.length === 0 ? 0 : dashboardData[8][0]?.paymentSuccess, dashboardData.length === 0 ? 0 : dashboardData[9][0]?.paymentFailed],
                backgroundColor: ["#ffc107", "#198754", "#dc3545"],
                borderColor: ["#ffc107", "#198754", "#dc3545"],
                borderWidth: 3
            }
        ]
    };

    const options = { plugins: { title: { display: true, text: "Transaction Graph" } } };

    return (
        <>
            <div>
                <Pie data={graph} options={options} />
            </div>
        </>
    )
}

export default DashboardMiddlePieChart;
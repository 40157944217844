import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Avatar from "react-avatar";
import useLoadingPage from "../../../../hooks/useLoadingPage";
import SpinnerForPage from "../../../../components/spinner/SpinnerForPage";
import SpinnerForTable from "../../../../components/spinner/SpinnerForTable";
import MainBreadcrumb from "../../../../components/breadcrumb/MainBreadcrumb";

const Properties = ({ urlMain, token, propertiesData, setPropertiesData, propertiesSearch, setpropertiesSearch, page, setPage }) => {

    const loadingPage = useLoadingPage();
    const [loadingTable, setLoadingTable] = useState(true);
    const navigate = useNavigate();


    const role = Number(localStorage.getItem('adminRole'))
    const firstPage = () => setPage(1);
    const pageMin = () => setPage((prevPage) => Math.max(prevPage - 1));
    const pageAdd = () => setPage((prevPage) => Math.min(prevPage + 1));

    const getPropertiesApi = async () => {
        try {
            setLoadingTable(true);
            let url
            if (role === 8) {
                url = `${urlMain}/properties/list`
            } else {
                url = `${urlMain}/properties?page=${page}&type=${propertiesSearch.type}&name=${propertiesSearch.name}&number=${propertiesSearch.number}&startDate=${propertiesSearch.startDate}&endDate=${propertiesSearch.endDate}`
            }
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': "Bearer " + token
                }
            })
            const data = await response.json();

            if (data.status === true) {
                setPropertiesData(data.data);
                setLoadingTable(false);
            }
        } catch (error) {
            console.error("An error occurred while fetching properties:", error);
        }
    };

    const changeSearch = (e) => setpropertiesSearch({ ...propertiesSearch, [e.target.name]: e.target.value });
    const handleReset = () => window.location.reload();


    useEffect(() => {
        document.title = "Properties - Admin Panel | CRM";
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        getPropertiesApi();
        // eslint-disable-next-line
    }, [page]);

    return (
        <>
            {
                loadingPage ? <SpinnerForPage /> :
                    <div className="container-fluid mt-5">
                        <div className="row">
                            <div className="col-md-6 text-left">
                                <h3 className="page-title">Properties</h3>
                                <ul className="breadcrumb">
                                    <MainBreadcrumb />
                                    <li className="breadcrumb-item">
                                        <span className="breadcrumb-active">Properties</span>
                                    </li>
                                </ul>
                            </div>
                            {role !== 8 && <div className="col-md-6 text-right">
                                <a href={`${urlMain}/downloadPropertyData`} download>
                                    <button type="button" className="btn btn-ff9b44 btn-round"><i className="fa fa-download" aria-hidden="true"></i> Download Properties Data</button>
                                </a>
                            </div>}
                        </div>
                        <div className="row mt-4">
                            {
                                propertiesData?.cardData?.map((element, index) => {
                                    return (
                                        <div className="col-md-3" key={index}>
                                            <div className="card">
                                                <div className="card-body text-center status-info">
                                                    <h6>{element.Heading}</h6>
                                                    <h4>{element.TotalProperties}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {role !== 8 && <div className="col-md-3">
                                <div className="card">
                                    <div className="card-body text-center status-info">
                                        <h6>Total Properties</h6>
                                        <h4>{propertiesData.totalData}</h4>
                                    </div>
                                </div>
                            </div>}
                        </div>
                        {role !== 8 && <div className="row">
                            <div className="col-md-2">
                                <div className="form-floating">
                                    <select className="form-select" id="type" name="type" value={propertiesSearch?.type ? propertiesSearch?.type : ""} onChange={changeSearch}>
                                        <option value="">---Select---</option>
                                        <option value="Hotel">Hotel</option>
                                        <option value="Cafe">Cafe</option>
                                        <option value="Business">Business</option>
                                        <option value="Retails">Retails</option>
                                        <option value="Educational">Educational</option>
                                        <option value="HouseHold">HouseHold</option>
                                        <option value="Bar/Restau">Restaurant/Bar</option>
                                    </select>
                                    <label htmlFor="type" className="mb-3">Status</label>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-floating">
                                    <input type="text" className="form-control" id="name" name="name" placeholder="Search" autoComplete="off" value={propertiesSearch?.name} onChange={changeSearch} />
                                    <label htmlFor="name" className="mb-3">Property Name</label>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-floating">
                                    <input type="text" className="form-control" id="number" name="number" placeholder="Search" autoComplete="off" value={propertiesSearch.number} onChange={changeSearch} />
                                    <label htmlFor="number" className="mb-3">Owner Number</label>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-floating">
                                    <input type="date" className="form-control" id="startDate" name="startDate" placeholder="Search" autoComplete="off" max={moment(new Date()).format("YYYY-MM-DD")} value={propertiesSearch.startDate} onChange={changeSearch} />
                                    <label htmlFor="startDate" className="mb-3">Start Date</label>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-floating">
                                    <input type="date" className="form-control" id="endDate" name="endDate" placeholder="Search" autoComplete="off" min={propertiesSearch.startDate} max={moment(new Date()).format("YYYY-MM-DD")} value={propertiesSearch.endDate} onChange={changeSearch} />
                                    <label htmlFor="endDate" className="mb-3">End Date</label>
                                </div>
                            </div>
                            <div className="col-md-1 pt-1">
                                <button type="button" className="btn btn-55ce63 btn-lg me-1" onClick={() => {
                                    getPropertiesApi();
                                    setPage(1);
                                }}>Search</button>
                            </div>
                            <div className="col-md-1 pt-1">
                                <button type="reset" className="btn btn-55ce63 btn-lg" onClick={handleReset}>Reset</button>
                            </div>
                        </div>}
                        <div className="card card-dark mt-4">
                            {
                                loadingTable ? <SpinnerForTable /> : propertiesData?.data?.length === 0 ? (
                                    <div className="text-center mt-5 mb-5 fw-bolder">No properties found at the moment!</div>
                                ) : (
                                    <div className="card-block table-border-style">
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr className="text-left">
                                                        <th scope="col"></th>
                                                        <th scope="col">Property Name</th>
                                                        {/* <th scope="col">Property Type</th> */}
                                                        <th scope="col">Total Emission</th>
                                                        {role !== 8 && <th scope="col">Contact Name</th>}
                                                        {role !== 8 && <th scope="col">Status</th>}
                                                        {/* <th scope="col">Property Creation Time</th> */}
                                                        <th scope="col">Total Waste</th>
                                                        {role === 8 && <th>Future Pickup</th>}
                                                        {role === 8 && <th>Last Pickup</th>}
                                                        {role !== 8 && <th>Creation Time</th>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        propertiesData?.data?.map((element, index) => {
                                                            return (
                                                                <tr className="text-left fw-normal cp" key={index} onClick={() => { navigate("/main:allPropertiesDetails", { state: element }) }}>
                                                                    <td><Avatar round={true} size="35" name={element.PropertyName} /></td>
                                                                    <td className="fw-bold">{element.PropertyName}<div className="fw-lighter text-muted">{element.Address.split(",")[element.Address.split(",").length - 4]}</div></td>
                                                                    <td>{element?.totalEmission ?? 'NA'}</td>
                                                                    {role !== 8 && <td>{element.ContactPerson} <div className="fw-lighter text-muted">{element.ContactNumber}</div></td>}
                                                                    {role !== 8 && <td>{element.Verified === 0 ? <span className="badge bg-warning">Pending</span> : element.Verified === 1 ? <span className="badge bg-success">Verified</span> : element.Verified === 2 ? <span className="badge bg-danger">Rejected</span> : ""}</td>}
                                                                    <td>{(element?.totalWaste ? element?.totalWaste : '0') + " kg"}</td>
                                                                    {role === 8 && <td>{element?.future_slot}</td>}
                                                                    {role === 8 && <td>{element?.last_pickup_date ?
                                                                        moment(element?.last_pickup_date).format("DD/MM/YYYY | hh:mm A").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" }) : 'NA'}</td>}
                                                                    {role !== 8 && <td>{element?.CreationTime ?
                                                                        moment(element?.CreationTime).format("DD/MM/YYYY | hh:mm A").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" }) : 'NA'}</td>}
                                                                    {/* <td>{element.Verified === 0 ? <i className="fa-solid fa-truck-pickup text-warning fs-5 cp" onClick={() => { openCanvas(); setPropertyId(element.PropertyId) }}></i> : element.Verified === 1 ? <i className="fa-solid fa-truck-pickup text-success fs-5 cp" onClick={() => { openCanvas(); setPropertyId(element.PropertyId) }}></i> : <span className="badge bg-danger"><i className="fa-solid fa-xmark"></i></span>}</td> */}
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="container mt-3 mb-3" id="paginationnumbers">
                                            <div className="pagination" id="paginationnumbers">
                                                <div className="page-item text-left">
                                                    <button className="page-link" disabled={page <= 1} onClick={firstPage}>
                                                        1
                                                    </button>
                                                </div>
                                                <div className="page-item text-left">
                                                    <button className="page-link" disabled={page <= 1} onClick={pageMin}>
                                                        &laquo; Previous
                                                    </button>
                                                </div>
                                                <div className="page-item">
                                                    <div className="page-link">
                                                        {page} of {propertiesData?.totalPages}
                                                    </div>
                                                </div>
                                                <div className="page-item text-left">
                                                    <button className="page-link" disabled={page === propertiesData?.totalPages} onClick={pageAdd}>
                                                        Next &raquo;
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </div>
                    </div >
            }
        </>
    )
}

export default Properties;
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_URL_BASE,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem('tokenAdminPanel');
            if (!token) {
                console.error("Token is not available.");
                throw new Error('401')
            } else {
                headers.set('Authorization', `Bearer ${token}`);
                // headers.set('mode', 'no-cors')
            }
            return headers;
        },
    }),
    endpoints: builder => ({}),
});

import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice";

// Create an entity adapter
const usersAdapter = createEntityAdapter({
    selectId: (user) => user.UserId,
    sortComparer: (a, b) => a.Name.localeCompare(b.Name),
});

// Define initial state for the slice
const initialState = usersAdapter.getInitialState({
    page: 1,
    totalPages: 0,
    status: '',
    name: '',
    number: '',
});

// Create the slice
const usersSlice = createSlice({
    name: 'usersList',
    initialState,
    reducers: {
        refresh(state) {
            state.page = 1;
            state.totalPages = 0;
            state.status = '';
            state.name = '';
            state.number = '';
        },
        setSearchParams(state, action) {
            const { status, name, number } = action.payload;
            state.status = status;
            state.name = name;
            state.number = number;
        },
        nextPage(state) {
            if (state.page < state.totalPages) state.page = state.page + 1
        },
        previousPage(state) {
            if (state.page > 1) state.page = state.page - 1
        },
        firstPage(state) {
            state.page = 1
        },
        lastPage(state) {
            state.page = state.totalPages
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                extendedApiSlice.endpoints.getUsersList.matchFulfilled,
                (state, action) => {
                    const { totalPages } = action.payload;
                    // usersAdapter.setAll(state, users);
                    state.totalPages = totalPages;
                }
            );
    },
});

// Export actions
export const { refresh, nextPage, previousPage, firstPage, lastPage, setSearchParams } = usersSlice.actions;

// Export the reducer
export default usersSlice.reducer;

// Export selectors
export const {
    selectAll: selectAllUsers,
    selectById: selectUserById,
    selectIds: selectUserIds,
} = usersAdapter.getSelectors((state) => state.usersList);

// Extend the apiSlice to include the getUsersList endpoint
export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getUsersList: builder.query({
            query: ({ page, status, name, number }) =>
                `/main/users?page=${page}&status=${status}&name=${name}&number=${number}`,
            transformResponse: (response) => ({
                totalPages: response.data.totalPages,
                totalUsers: response.data.totalData,
                users: response.data.data,
            }),
            transformErrorResponse: (error) => ({
                status: error.status,
                error: error,
            }),
            providesTags: (result, error, type) => {
                if (error) return [];
                if (result?.users) {
                    return [
                        { type, id: "List" }, // Tag for the entire list of this type
                        ...result.users.map(user => ({ type, id: user.UserId })) // Tags for individual entities
                    ];
                }
                return [];
            },
        }),
    }),
});

// Export hooks generated by the endpoint
export const { useGetUsersListQuery } = extendedApiSlice;

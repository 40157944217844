import React from "react";
import { NavLink } from "react-router-dom";

const SidebarLink = ({ to, icon, text }) => (
    <NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2" : "font-thin nav-link select2 active"} to={to}>
        <li className="nav-item select mt-1 mb-1 ms-3">
            <span className="h6">
                <i className={`fa-solid fa-${icon}`}></i> &nbsp; {text}
            </span>
        </li>
    </NavLink>
);

const B2GSidebar = () => {

    return (
        <>
            <nav id="sidebar" className="sidebar-b2g bg-left-navabr">
                <ul className="navbar-nav scrollBar ms-auto mb-lg-0 d-flex flex-column text-left mt-1">
                    <li className="menu-title h6 mt-1 mb-1">Dashboard</li>
                    <SidebarLink to="/" icon="home" text="B2G Dashboard" />
                    <li className="menu-title h6 mt-1 mb-1">B2G</li>
                    <SidebarLink to="/b2g/states" icon="1" text="States" />
                    <SidebarLink to="/b2g/districts" icon="2" text="Districts" />
                    <SidebarLink to="/b2g/communities" icon="3" text="Community's" />
                    <SidebarLink to="/b2g/wards" icon="4" text="Wards" />
                    {/* <SidebarLink to="/b2g/communityAdmin" icon="4" text="Community Admin" /> */}
                    <SidebarLink to="/b2g/admin" icon="5" text="Admin" />
                    <SidebarLink to="/b2g/survey" icon="6" text="survey" />
                    <SidebarLink to="/b2g/driverLive" icon="7" text="Driver live" />
                    {/* <SidebarLink to="/b2g/surveyDetail" icon="7" text="survey deatail" /> */}
                </ul>
            </nav>
        </>
    )
}

export default B2GSidebar;
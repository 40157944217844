import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice";

const propertiesAdaptor = createEntityAdapter({
    selectId: (property) => (property.prp_id)
})

const initialState = propertiesAdaptor.getInitialState({
    page: 1,
    totalPages: 0,
    propertyType: '',
    ward_id: '',
    community_id: '',
    propertyName: '',
    propertyNumber: '',
    propertyStatus: '',
    userName: '',
    userNumber: '',
    startDate: '',
    endDate: ''
})

const propertiesSlice = createSlice({
    name: 'b2gProperties',
    initialState,
    reducers: {
        refreshSurveyPropertiesList(state) {
            state.page = 1
            // state.totalPages = 0
            state.ward_id = ''
            state.community_id = ''
            state.propertyName = ''
            state.propertyNumber = ''
            state.propertyStatus = ''
            state.propertyType = ''
            state.userName = ''
            state.userNumber = ''
            state.startDate = ''
            state.endDate = ''
        },
        setSearchParamsSurveyPropertiesList(state, action) {
            const { ward_id, community_id, propertyName, propertyNumber, propertyStatus, propertyType, userName, userNumber, startDate, endDate } = action.payload
            state.ward_id = ward_id
            state.community_id = community_id
            state.propertyName = propertyName
            state.propertyNumber = propertyNumber
            state.propertyStatus = propertyStatus
            state.propertyType = propertyType
            state.userNumber = userNumber
            state.userName = userName
            state.startDate = startDate
            state.endDate = endDate
        },
        nextPageSurveyPropertiesList(state) {
            if (state.page < state.totalPages) state.page = state.page + 1
        },
        previousPageSurveyPropertiesList(state) {
            if (state.page > 1) state.page = state.page - 1
        },
        firstPageSurveyPropertiesList(state) {
            state.page = 1
        },
        lastPageSurveyPropertiesList(state) {
            state.page = state.totalPages
        }
    },
    // extraReducers: (builder) => {
    //     builder
    //         .addMatcher()
    // }
})

export const { firstPageSurveyPropertiesList, lastPageSurveyPropertiesList, previousPageSurveyPropertiesList, nextPageSurveyPropertiesList, refreshSurveyPropertiesList, setSearchParamsSurveyPropertiesList } = propertiesSlice.actions

export default propertiesSlice.reducer
export const { selectAll: selectAllUsers,
    selectById: selectUserById,
    selectIds: selectUserIds, } = propertiesAdaptor.getSelectors((state => state.b2gProperties))

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getPropertiesList: builder.query({
            query: ({ ward_id, community_id, startDate, endDate, propertyType }) => `/b2g/eco-admin?ward_id=${ward_id}&community_id=${community_id}&start_date=${startDate}&end_date=${endDate}&property_type=${propertyType}`,
            transformResponse: (response) => ({
                // totalPages:response.totalPages,
                properties: response.data
            }),
            transformErrorResponse: (error) => ({
                status: error.status,
                error: error
            }),
            providesTags: (result, error, type) => {
                if (error) return []
                if (result.properties) {
                    return [
                        { type, id: 'propertiesList' },
                        ...result.properties.map(property => ({ type, id: property.prp_id }))
                    ]
                }
                return []
            }
        })
    })
})

export const { useGetPropertiesListQuery } = extendedApiSlice
import React from "react";

const SpinnerForPage = () => {
    return (
        <>
            <div className="text-center">
                <img className="my-5" src="assets/images/loader-page.gif" alt="spinner-for-page" />
            </div>
        </>
    )
}

export default SpinnerForPage;
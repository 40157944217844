import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import ReactNotificationComponent from "./ReactNotificationComponent";
import { onMessageListener } from "../firebaseInit";

const Mess = () => {

    const [notification, setNotification] = useState({ title: "", body: "" });
    const [show, setShow] = useState(false);

    useEffect(() => {
        const handleNotification = async () => {
            try {
                const payload = await onMessageListener();
                setShow(true);
                setNotification({ title: payload.notification.title, body: payload.notification.body });
            } catch (err) {
                console.log("Failed:", err);
            }
        }
        handleNotification();
    }, []);

    return (
        <>
            {show && <ReactNotificationComponent title={notification.title} body={notification.body} />}
        </>
    )
}

export default Mess;
import React, { useState } from "react";
import CrmContext from "./CrmContext";

const CrmState = ({ children, urlAdmin, urlCrm, urlHR, token }) => {

    const [reasons, setReasons] = useState([]);
    const [reasonStatus, setReasonStatus] = useState("");
    const [vehicles, setVehicles] = useState([]);
    const [vehicleStatus, setVehicleStatus] = useState("");
    const [areas, setAreas] = useState([]);
    const [areaStatus, setAreaStatus] = useState("");
    const [nodes, setNodes] = useState([]);
    const [pickups, setPickups] = useState([]);
    const [pickupsEntry, setPickupsEntry] = useState([]);
    const [pickupsSearch, setPickupsSearch] = useState({ startDate: "", endDate: "" });
    const [offlineDeals, setOfflineDeals] = useState([]);
    const [offlineDealsSearch, setOfflineDealsSearch] = useState({ ohsnCode: "", godownNodeId: "", startDate: "", endDate: "" });
    const [crmReasons, setCrmReasons] = useState([]);
    const [pickupStatus, setPickupStatus] = useState([]);
    const [wasteType, setWasteType] = useState([]);
    const [paymentMode, setPaymentMode] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [designations, setDesignations] = useState([]);
    const [roles, setRoles] = useState([]);

    const [page, setPage] = useState(1);

    const [loadingTable, setLoadingTable] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);

    const getData = async (url) => {
        try {
            setLoadingTable(true);
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            });
            if (response.ok) {
                const data = await response.json();
                setLoadingTable(false);
                return data;
            } else {
                console.error("API request was not successful");
                setLoadingTable(false);
            }
        } catch (error) {
            console.error("An error occurred while fetching data", error);
            setLoadingTable(false);
        }
    };

    const postData = async (url, body) => {
        try {
            setLoadingButton(true);
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                },
                body: JSON.stringify(body)
            });
            if (response.ok) {
                const data = await response.json();
                setLoadingButton(false);
                return data;
            } else {
                console.error("API request was not successful");
                setLoadingButton(false);
            }
        } catch (error) {
            console.error("An error occurred while adding data", error);
            setLoadingButton(false);
        }
    };

    const patchData = async (url, body) => {
        try {
            setLoadingButton(true);
            const response = await fetch(url, {
                method: "PATCH",
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                },
                body: JSON.stringify(body)
            });
            if (response.ok) {
                const data = await response.json();
                setLoadingButton(false);
                return data;
            } else {
                console.error("API request was not successful");
                setLoadingButton(false);
            }
        } catch (error) {
            console.error("An error occurred while editing data", error);
            setLoadingButton(false);
        }
    };

    const deleteData = async (url, method, body = null) => {
        try {
            setLoadingTable(true);
            const response = await fetch(url, {
                method,
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                },
                body: body ? JSON.stringify(body) : null
            });
            if (response.ok) {
                const data = await response.json();
                setLoadingTable(false);
                return data;
            } else {
                console.error("API request was not successful");
                setLoadingTable(false);
            }
        } catch (error) {
            console.error("An error occurred while fetching data", error);
            setLoadingTable(false);
        }
    };

    // ADMIN PANEL API CALL START
    // ADMIN PANEL: Get reasons
    const getReasons = async () => {
        try {
            const data = await getData(`${urlAdmin}/reasons?active=${reasonStatus}`);
            if (data && data.status === true) {
                setReasons(data.data);
                setReasonStatus(reasonStatus);
            }
        } catch (error) {
            console.error("An error occurred while fetching reasons:", error);
        }
    };

    // ADMIN PANEL: Add reason
    const addReasons = async (title, message) => {
        try {
            const data = await postData(`${urlAdmin}/addReason`, { title: title, message: message });
            if (data && data.status === true) {
                setReasons((prevReasons) => [...prevReasons, data.data]);
            }
        } catch (error) {
            console.error("An error occurred while adding reason:", error);
        }
    };

    // ADMIN PANEL: Edit reason
    const editReasons = async (reasonId, title, message) => {
        try {
            const data = await patchData(`${urlAdmin}/updateReason/${reasonId}`, { title, message });
            if (data && data.status === true) {
                setReasons((prevReasons) => prevReasons.map((reason) => reason.reasonId === reasonId ? { ...reason, title, message } : reason));
            }
        } catch (error) {
            console.error("An error occurred while editing reason:", error);
        }
    };

    // ADMIN PANEL: Delete reason
    const deleteReasons = async (reasonId) => {
        try {
            const data = await deleteData(`${urlAdmin}/activeReason/${reasonId}`, "PATCH");
            if (data && data.status === true) {
                setReasons((prevReasons) => prevReasons.filter((reason) => reason.reasonId !== reasonId));
            }
        } catch (error) {
            console.error("An error occurred while deleting reason:", error);
        }
    };

    // ADMIN PANEL: Get Vehicles
    const getVehicles = async () => {
        try {
            const data = await getData(`${urlAdmin}/vehicleList?active=${vehicleStatus}`);
            if (data && data.status === true) {
                setVehicles(data.data);
                setVehicleStatus(vehicleStatus);
            }
        } catch (error) {
            console.error("An error occurred while fetching vehicles:", error);
        }
    };

    // ADMIN PANEL: Add vehicle    
    const addVehicles = async (vehicleName, vehicleNumber) => {
        try {
            const data = await postData(`${urlAdmin}/addVehicle`, { vehicleName: vehicleName, vehicleNumber: vehicleNumber });
            if (data && data.status === true) {
                setVehicles((prevVehicles) => [...prevVehicles, data.data]);
            }
        } catch (error) {
            console.error("An error occurred while adding vehicle:", error);
        }
    };

    // ADMIN PANEL: Edit vehicle
    const editVehicles = async (vehicleId, vehicleName, vehicleNumber) => {
        try {
            const data = await patchData(`${urlAdmin}/updateVehicle/${vehicleId}`, { vehicleName, vehicleNumber });
            if (data && data.status === true) {
                setVehicles((prevVehicles) => prevVehicles.map((vehicle) => vehicle.VehicleId === vehicleId ? { ...vehicle, vehicleName, vehicleNumber } : vehicle));
            }
        } catch (error) {
            console.error("An error occurred while editing vehicle:", error);
        }
    };

    // ADMIN PANEL: Delete vehicle
    const deleteVehicles = async (vehicleId) => {
        try {
            const data = await deleteData(`${urlAdmin}/deleteVehicle/${vehicleId}`, "PATCH");
            if (data && data.status === true) {
                setVehicles((prevVehicles) => prevVehicles.filter((vehicle) => vehicle.VehicleId !== vehicleId));
            }
        } catch (error) {
            console.error("An error occurred while deleting area:", error);
        }
    };

    // ADMIN PANEL: Get areas
    const getAreas = async () => {
        try {
            const data = await getData(`${urlAdmin}/areas?status=${areaStatus}`);
            if (data && data.status === true) {
                setAreas(data.data);
                setAreaStatus(areaStatus);
            }
        } catch (error) {
            console.error("An error occurred while fetching areas:", error);
        }
    };

    // ADMIN PANEL: Add area
    const addAreas = async (areaName, areaLat, areaLng) => {
        try {
            const data = await postData(`${urlAdmin}/addArea`, { areaName: areaName, areaLat: areaLat, areaLng: areaLng });
            if (data && data.status === true) {
                setAreas((prevAreas) => [...prevAreas, data.data]);
            }
        } catch (error) {
            console.error("An error occurred while adding area:", error);
        }
    };

    // ADMIN PANEL: Edit area
    const editAreas = async (areaId, name, lat, lng) => {
        try {
            const data = await patchData(`${urlAdmin}/updateArea/${areaId}`, { name, lat, lng });
            if (data && data.status === true) {
                setAreas((prevAreas) => prevAreas.map((area) => area.area_id === areaId ? { ...area, name, lat, lng } : area));
            }
        } catch (error) {
            console.error("An error occurred while editing area:", error);
        }
    };

    // ADMIN PANEL: Delete area
    const deleteAreas = async (areaId) => {
        try {
            const data = await deleteData(`${urlAdmin}/activationArea/${areaId}`, "PATCH");
            if (data && data.status === true) {
                setAreas((prevAreas) => prevAreas.filter((area) => area.area_id !== areaId));
            }
        } catch (error) {
            console.error("An error occurred while deleting area:", error);
        }
    };

    // ADMIN PANEL: Get nodes
    const getNodes = async () => {
        try {
            const data = await getData(`${urlAdmin}/node`);
            if (data && data.status === true) {
                setNodes(data.data);
            }
        } catch (error) {
            console.error("An error occurred while fetching nodes:", error);
        }
    };

    // ADMIN PANEL: Add node
    const addNodes = async (name) => {
        try {
            const data = await postData(`${urlAdmin}/node`, { name: name });
            if (data && data.status === true) {
                setNodes((prevNodes) => [...prevNodes, data.data]);
            }
        } catch (error) {
            console.error("An error occurred while adding node:", error);
        }
    };

    // ADMIN PANEL: Edit node
    const editNodes = async (id, name) => {
        try {
            const data = await patchData(`${urlAdmin}/node/${id}`, { name });
            if (data && data.status === true) {
                setNodes((prevNodes) => prevNodes.map((node) => node.id === id ? { ...node, name } : node));
            }
        } catch (error) {
            console.error("An error occurred while editing node:", error);
        }
    };

    // ADMIN PANEL: Delete node
    const deleteNodes = async (id) => {
        try {
            const data = await deleteData(`${urlAdmin}/node/${id}`, "DELETE");
            if (data && data.status === true) {
                setNodes((prevNodes) => prevNodes.filter((node) => node.id !== id));
            }
        } catch (error) {
            console.error("An error occurred while deleting node:", error);
        }
    };

    // ADMIN PANEL: Get pickups
    const getPickups = async (driverId, pageNumber, startDate, endDate) => {
        try {
            let url
            if (!startDate && !endDate && !driverId) {
                url = `${urlAdmin}/godownWaste/?pageNumber=${pageNumber}`;
            } else if (startDate && endDate && driverId) {
                url = `${urlAdmin}/godownWaste/?driverId=${driverId}&pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`;
            }
            else if (!driverId && startDate && endDate) {
                url = `${urlAdmin}/godownWaste/?&pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`;
            } else if (!startDate && !endDate && driverId) {
                url = `${urlAdmin}/godownWaste/?driverId=${driverId}&pageNumber=${pageNumber}`;
            } else if ((!startDate && endDate) || (startDate && !endDate)) {
                url = `${urlAdmin}/godownWaste/?driverId=${driverId}&pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`;
            }
            const data = await getData(url);
            if (data && data.status === true) {
                setPickups(data.data[0]);
            }
        } catch (error) {
            console.error("An error occurred while fetching pickups:", error);
        }
    };
    // Admin panel get pickups entry
    const getPickupsEntry = async (pageNumber, startDate, endDate) => {
        try {
            let url
            if (!startDate && !endDate) {
                url = `${urlAdmin}/pickups/?pageNumber=${pageNumber}`;
            } else if (startDate && endDate) {
                url = `${urlAdmin}/pickups/?pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`;
            }
            else if ((!startDate && endDate) || (startDate && !endDate)) {
                url = `${urlAdmin}/pickups/?pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`;
            }
            const data = await getData(url);
            if (data && data.status === true) {
                setPickupsEntry(data.data[0]);
            }
        } catch (error) {
            console.error("An error occurred while fetching pickups:", error);
        }
    };
    // ADMIN PANEL: Add pickup
    const addPickups = async (date, driver_id, node_waste_obj) => {
        const keyValueArr = Object.entries(node_waste_obj);
        for (let i = 0; i < keyValueArr.length; i++) {
            keyValueArr[i] = {
                "id": parseInt(keyValueArr[i][0]),
                "name": keyValueArr[i][1].name,
                "weight": parseFloat(keyValueArr[i][1].weight)
            }
        };
        try {
            const data = await postData(`${urlAdmin}/pickups`, { date: date, driver_id: driver_id, node_waste_arr: keyValueArr });
            if (data && data.status === true) {
                // setPickupsEntry((prevPickups) => [...prevPickups, data.data]);
                getPickupsEntry(1);
            }
        } catch (error) {
            console.error("An error occurred while adding pickup:", error);
        }
    };

    // ADMIN PANEL: Edit pickup
    const editPickups = async (id, date, driver_id, node_waste_arr) => {
        const keyValueArr1 = Object.entries(node_waste_arr);
        for (let i = 0; i < keyValueArr1.length; i++) {
            keyValueArr1[i] = {
                "id": parseInt(keyValueArr1[i][0]),
                "name": keyValueArr1[i][1].name,
                "weight": parseFloat(keyValueArr1[i][1].weight)
            }
        };
        try {
            const data = await patchData(`${urlAdmin}/pickups/${id}`, { date: date, driver_id: driver_id, node_waste_arr: keyValueArr1 });
            if (data && data.status === true) {
                // setPickupsEntry((prevPickups) => prevPickups.map((pickup) => pickup.id === id ? { ...pickup, date, driver_id, node_waste_arr } : pickup));
                getPickupsEntry(1);
            }
        } catch (error) {
            console.error("An error occurred while editing pickup:", error);
        }
    };

    // ADMIN PANEL: Delete pickup
    const deletePickups = async (id) => {
        try {
            const data = await deleteData(`${urlAdmin}/pickups/${id}`, "DELETE");
            if (data && data.status === true) {
                // setPickupsEntry((prevPickups) => {
                //     console.log('prevPickups',prevPickups);
                //     const newPickups = prevPickups.filter((pickup) => pickup.data.id !== id);
                //     return newPickups;
                // })
                getPickupsEntry(1);
            }
        } catch (error) {
            console.error("An error occurred while deleting pickup:", error);
        }
    };

    // ADMIN PANEL: Get offline deals
    const getOfflineDeals = async () => {
        try {
            const data = await getData(`${urlAdmin}/offlineDeal?o_hsn_code=${offlineDealsSearch.ohsnCode}&godown_node_id=${offlineDealsSearch.godownNodeId}&start_date=${offlineDealsSearch.startDate}&end_date=${offlineDealsSearch.endDate}`);
            if (data && data.status === true) {
                setOfflineDeals(data.data);
            }
        } catch (error) {
            console.error("An error occurred while fetching offline deals:", error);
        }
    };

    // ADMIN PANEL: Add offline deal
    const addOfflineDeals = async (godown_node_id, o_hsn_code, o_item_description, o_weight, o_rate, o_cgst, o_sgst, o_amount, o_buyer_name, o_gstin, o_invoice, o_complete_date) => {
        try {
            const data = await postData(`${urlAdmin}/offlineDeal`, { godown_node_id, o_hsn_code, o_item_description, o_weight, o_rate, o_cgst, o_sgst, o_amount, o_buyer_name, o_gstin, o_invoice, o_complete_date });
            if (data && data.status === true) {
                setOfflineDeals((prevOfflineDeals) => [...prevOfflineDeals, data.data]);
            }
        } catch (error) {
            console.error("An error occurred while adding offline deal:", error);
        }
    };

    // ADMIN PANEL: Edit offline deal
    const editOfflineDeals = async (o_id, godown_node_id, o_hsn_code, o_item_description, o_weight, o_rate, o_cgst, o_sgst, o_amount, o_buyer_name, o_gstin, o_invoice, o_complete_date) => {
        try {
            const data = await patchData(`${urlAdmin}/offlineDeal`, { o_id, godown_node_id, o_hsn_code, o_item_description, o_weight, o_rate, o_cgst, o_sgst, o_amount, o_buyer_name, o_gstin, o_invoice, o_complete_date });
            if (data && data.status === true) {
                setOfflineDeals((prevOfflineDeals) => prevOfflineDeals.map((offlineDeal) => offlineDeal.o_id === o_id ? { ...offlineDeal, godown_node_id, o_hsn_code, o_item_description, o_weight, o_rate, o_cgst, o_sgst, o_amount, o_buyer_name, o_gstin, o_invoice, o_complete_date } : offlineDeal));
            }
        } catch (error) {
            console.error("An error occurred while editing node:", error);
        }
    };

    // ADMIN PANEL: Delete offline deal
    const deleteOfflineDeals = async (o_id) => {
        try {
            const body = { o_id };
            const data = await deleteData(`${urlAdmin}/offlineDeal`, "DELETE", body);
            if (data && data.status === true) {
                setOfflineDeals((offlineDeal) => offlineDeal.filter((offlineDeal) => offlineDeal.o_id !== o_id));
            }
        } catch (error) {
            console.error("An error occurred while deleting offline deal:", error);
        }
    };
    // ADMIN PANEL API CALL END

    // CRM PANEL API CALL START
    // CRM PANEL:  Get crm reasons
    const getCrmReasons = async () => {
        try {
            const data = await getData(`${urlCrm}/reasonList`);
            if (data && data.status === true) {
                setCrmReasons(data.data);
            }
        } catch (error) {
            console.error("An error occurred while fetching crm reasons:", error);
        }
    };

    // CRM PANEL: Add crm reason
    const addCrmReasons = async (description) => {
        try {
            const data = await postData(`${urlCrm}/addReasons`, { description });
            if (data && data.status === true) {
                setCrmReasons((prevCrmReasons) => [...prevCrmReasons, data.data]);
            }
        } catch (error) {
            console.error("An error occurred while adding crm reason:", error);
        }
    };

    // CRM PANEL: Edit crm reason
    const editCrmReasons = async (crmReasonId, description) => {
        try {
            const data = await patchData(`${urlCrm}/updateReason/${crmReasonId}`, { description });
            if (data && data.status === true) {
                setCrmReasons((prevCrmReasons) => prevCrmReasons.map((crmReasons) => crmReasons.reason_id === crmReasonId ? { ...crmReasons, description } : crmReasons));
            }
        } catch (error) {
            console.error("An error occurred while editing crm reason:", error);
        }
    };

    // CRM PANEL: Delete crm reason
    const deleteCrmReasons = async (crmReasonId) => {
        try {
            const data = await deleteData(`${urlCrm}/deleteReason/${crmReasonId}`, "DELETE");
            if (data && data.status === true) {
                setCrmReasons((prevCrmReasons) => prevCrmReasons.filter((crmReason) => crmReason.reason_id !== crmReasonId));
            }
        } catch (error) {
            console.error("An error occurred while deleting crm reason:", error);
        }
    };

    // CRM PANEL: Get pickup status
    const getPickupsStatus = async () => {
        try {
            const data = await getData(`${urlCrm}/pickupStatus`);
            if (data && data.status === true) {
                setPickupStatus(data.data);
            }
        } catch (error) {
            console.error("An error occurred while fetching pickup status:", error);
        }
    };

    // CRM PANEL: Add pickup status
    const addPickupsStatus = async (title) => {
        try {
            const data = await postData(`${urlCrm}/addPickupStatus`, { title });
            if (data && data.status === true) {
                setPickupStatus((prevPickupStatus) => [...prevPickupStatus, data.data]);
            }
        } catch (error) {
            console.error("An error occurred while adding pickup status:", error);
        }
    };

    // CRM PANEL: Edit pickup status
    const editPickupsStatus = async (pickupStatusId, title) => {
        try {
            const data = await patchData(`${urlCrm}/updatePickupStatus/${pickupStatusId}`, { title });
            if (data && data.status === true) {
                setPickupStatus((prevPickupStatus) => prevPickupStatus.map((prevPickupStatus) => prevPickupStatus.status_id === pickupStatusId ? { ...prevPickupStatus, title } : prevPickupStatus));
            }
        } catch (error) {
            console.error("An error occurred while editing pickup status:", error);
        }
    };

    // CRM PANEL: Delete pickup status
    const deletePickupsStatus = async (pickupStatusId) => {
        try {
            const data = await deleteData(`${urlCrm}/deletePickupStatus/${pickupStatusId}`, "DELETE");
            if (data && data.status === true) {
                setPickupStatus((prevPickupStatus) => prevPickupStatus.filter((prevPickupStatus) => prevPickupStatus.status_id !== pickupStatusId));
            }
        } catch (error) {
            console.error("An error occurred while deleting pickup status:", error);
        }
    };

    // CRM PANEL: Get waste types
    const getWasteTypes = async () => {
        try {
            const data = await getData(`${urlCrm}/wasteTypes`);
            if (data && data.status === true) {
                setWasteType(data.data);
            }
        } catch (error) {
            console.error("An error occurred while fetching waste types:", error);
        }
    };

    // CRM PANEL: Add waste type
    const addWasteTypes = async (title) => {
        try {
            const data = await postData(`${urlCrm}/addWasteType`, { title });
            if (data && data.status === true) {
                setWasteType((prevWasteType) => [...prevWasteType, data.data]);
            }
        } catch (error) {
            console.error("An error occurred while adding waste type:", error);
        }
    };

    // CRM PANEL: Edit waste type
    const editWasteTypes = async (wasteTypeId, title) => {
        try {
            const data = await patchData(`${urlCrm}/updateWasteType/${wasteTypeId}`, { title });
            if (data && data.status === true) {
                setWasteType((prevWasteType) => prevWasteType.map((prevWasteType) => prevWasteType.waste_type_id === wasteTypeId ? { ...prevWasteType, title } : prevWasteType));
            }
        } catch (error) {
            console.error("An error occurred while editing waste type:", error);
        }
    };

    // CRM PANEL: Delete waste type
    const deleteWasteTypes = async (wasteTypeId) => {
        try {
            const data = await deleteData(`${urlCrm}/deleteWasteType/${wasteTypeId}`, "DELETE");
            if (data && data.status === true) {
                setWasteType((prevWasteType) => prevWasteType.filter((prevWasteType) => prevWasteType.waste_type_id !== wasteTypeId));
            }
        } catch (error) {
            console.error("An error occurred while deleting waste type:", error);
        }
    };

    // CRM PANEL: Get payment modes
    const getPaymentModes = async () => {
        try {
            const data = await getData(`${urlCrm}/paymentModes`);
            if (data && data.status === true) {
                setPaymentMode(data.data);
            }
        } catch (error) {
            console.error("An error occurred while fetching payment modes:", error);
        }
    };

    // CRM PANEL: Add payment mode
    const addPaymentModes = async (title) => {
        try {
            const data = await postData(`${urlCrm}/addPaymentMode`, { title });
            if (data && data.status === true) {
                setPaymentMode((prevPaymentMode) => [...prevPaymentMode, data.data]);
            }
        } catch (error) {
            console.error("An error occurred while adding payment mode:", error);
        }
    };

    // CRM PANEL: Edit payment mode
    const editPaymentModes = async (paymentModeId, title) => {
        try {
            const data = await patchData(`${urlCrm}/updatePaymentMode/${paymentModeId}`, { title });
            if (data && data.status === true) {
                setPaymentMode((prevPaymentMode) => prevPaymentMode.map((prevPaymentMode) => prevPaymentMode.payment_mode_id === paymentModeId ? { ...prevPaymentMode, title } : prevPaymentMode));
            }
        } catch (error) {
            console.error("An error occurred while editing payment mode:", error);
        }
    };

    // CRM PANEL: Delete payment mode
    const deletePaymentModes = async (paymentModeId) => {
        try {
            const data = await deleteData(`${urlCrm}/deletePaymentMode/${paymentModeId}`, "DELETE");
            if (data && data.status === true) {
                setPaymentMode((prevPaymentMode) => prevPaymentMode.filter((prevPaymentMode) => prevPaymentMode.payment_mode_id !== paymentModeId));
            }
        } catch (error) {
            console.error("An error occurred while deleting payment mode:", error);
        }
    };
    // CRM PANEL API CALL END

    // HR PANEL API CALL START
    // HR PANEL: Get departmnents
    const getDepartments = async () => {
        try {
            const data = await getData(`${urlHR}/departmentList`);
            if (data && data.status === true) {
                setDepartments(data.data);
            }
        } catch (error) {
            console.error("An error occurred while fetching departments:", error);
        }
    };

    // HR PANEL: Add department
    const addDepartments = async (departmentName) => {
        try {
            const data = await postData(`${urlHR}/addDepartment`, { departmentName });
            if (data && data.status === true) {
                setDepartments((prevDepartment) => [...prevDepartment, data.data]);
            }
        } catch (error) {
            console.error("An error occurred while adding department:", error);
        }
    };

    // HR PANEL: Edit departmnent
    const editDepartments = async (departmentId, departmentName) => {
        try {
            const data = await postData(`${urlHR}/updateDepartment`, { departmentId, departmentName });
            if (data && data.status === true) {
                setDepartments((prevDepartment) => prevDepartment.map((prevDepartment) => prevDepartment.DepartmentId === departmentId ? { ...prevDepartment, departmentName } : prevDepartment));
            }
        } catch (error) {
            console.error("An error occurred while editing department:", error);
        }
    };

    // HR PANEL: Delete department
    const deleteDepartments = async (departmentId) => {
        try {
            const body = { departmentId };
            const data = await deleteData(`${urlHR}/activationDepartment`, "POST", body);
            if (data && data.status === true) {
                setPaymentMode((prevDepartment) => prevDepartment.filter((prevDepartment) => prevDepartment.DepartmentId !== departmentId));
            }
        } catch (error) {
            console.error("An error occurred while deleting department:", error);
        }
    };

    // HR PANEL: Get designations
    const getDesignations = async () => {
        try {
            const data = await getData(`${urlHR}/designationList`);
            if (data && data.status === true) {
                setDesignations(data.data);
            }
        } catch (error) {
            console.error("An error occurred while fetching designations:", error);
        }
    };

    // HR PANEL: Add designation
    const addDesignations = async (designationName, departmentId) => {
        try {
            const data = await postData(`${urlHR}/addDesignation`, { designationName, departmentId });
            if (data && data.status === true) {
                setDesignations((prevDesignation) => [...prevDesignation, data.data]);
            }
        } catch (error) {
            console.error("An error occurred while adding designation:", error);
        }
    };

    // HR PANEL: Edit designation
    const editDesignations = async (designationId, designationName) => {
        try {
            const data = await postData(`${urlHR}/updateDesignation`, { designationId, designationName });
            if (data && data.status === true) {
                setDesignations((prevDesignation) => prevDesignation.map((prevDesignation) => prevDesignation.DesignationId === designationId ? { ...prevDesignation, designationName } : prevDesignation));
            }
        } catch (error) {
            console.error("An error occurred while editing designation:", error);
        }
    };

    // HR PANEL: Delete designation
    const deleteDesignations = async (designationId) => {
        try {
            const body = { designationId };
            const data = await deleteData(`${urlHR}/activationDesignation`, "POST", body);
            if (data && data.status === true) {
                setDesignations((prevDesignation) => prevDesignation.filter((prevDesignation) => prevDesignation.DesignationId !== designationId));
            }
        } catch (error) {
            console.error("An error occurred while deleting designation:", error);
        }
    };

    // HR PANEL: Get roles
    const getRoles = async () => {
        try {
            const data = await getData(`${urlHR}/rolesList`);
            if (data && data.status === true) {
                setRoles(data.data);
            }
        } catch (error) {
            console.error("An error occurred while fetching roles:", error);
        }
    };

    // HR PANEL: Add role
    const addRoles = async (roleName) => {
        try {
            const data = await postData(`${urlHR}/addRoles`, { roleName });
            if (data && data.status === true) {
                setRoles((prevRole) => [...prevRole, data.data]);
            }
        } catch (error) {
            console.error("An error occurred while adding role:", error);
        }
    };

    // HR PANEL: Edit role
    const editRoles = async (roleId, roleName) => {
        try {
            const data = await postData(`${urlHR}/updateRole`, { roleId, roleName });
            if (data && data.status === true) {
                setRoles((prevRole) => prevRole.map((prevRole) => prevRole.RoleId === roleId ? { ...prevRole, roleName } : prevRole));
            }
        } catch (error) {
            console.error("An error occurred while editing role:", error);
        }
    };

    // HR PANEL: Delete role
    const deleteRoles = async (roleId) => {
        try {
            const body = { roleId };
            const data = await deleteData(`${urlHR}/activationRole`, "POST", body);
            if (data && data.status === true) {
                setRoles((prevRole) => prevRole.filter((prevRole) => prevRole.RoleId !== roleId));
            }
        } catch (error) {
            console.error("An error occurred while deleting role:", error);
        }
    };
    // HR PANEL API CALL END

    return (
        <>
            <CrmContext.Provider value={{ loadingTable, loadingButton, page, setPage, reasons, getReasons, reasonStatus, setReasonStatus, addReasons, editReasons, deleteReasons, vehicles, getVehicles, vehicleStatus, setVehicleStatus, addVehicles, editVehicles, deleteVehicles, areas, getAreas, areaStatus, setAreaStatus, addAreas, editAreas, deleteAreas, nodes, getNodes, addNodes, editNodes, deleteNodes, pickups, pickupsEntry, getPickups, getPickupsEntry, pickupsSearch, setPickupsSearch, addPickups, editPickups, deletePickups, offlineDeals, getOfflineDeals, offlineDealsSearch, setOfflineDealsSearch, addOfflineDeals, editOfflineDeals, deleteOfflineDeals, crmReasons, getCrmReasons, addCrmReasons, editCrmReasons, deleteCrmReasons, pickupStatus, getPickupsStatus, addPickupsStatus, editPickupsStatus, deletePickupsStatus, wasteType, getWasteTypes, addWasteTypes, editWasteTypes, deleteWasteTypes, paymentMode, getPaymentModes, addPaymentModes, editPaymentModes, deletePaymentModes, departments, getDepartments, addDepartments, editDepartments, deleteDepartments, designations, getDesignations, addDesignations, editDesignations, deleteDesignations, roles, getRoles, addRoles, editRoles, deleteRoles }}>
                {children}
            </CrmContext.Provider>
        </>
    )
}

export default CrmState;
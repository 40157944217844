import React, { useState, useRef, useEffect } from "react";
import swal from "sweetalert";
import secureLocalStorage from "react-secure-storage";
import { useDispatch, useSelector } from "react-redux";

const AdminUpdatePassword = ({ urlMain, refOpenUpdate }) => {

    const [oldPassword, setOldPassword] = useState();
    const [newPassword, setNewPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [confirmPasswordText, setConfirmPasswordText] = useState();
    const [loadingButton, setLoadingButton] = useState(false);
    const refCloseUpdate = useRef(null);

    const { token } = useSelector(state => state.auth)

    const handleLogout = () => {
        secureLocalStorage.clear();
        window.location.reload();
    };

    const handleChangePassword = async (e) => {
        e.preventDefault();
        try {
            setLoadingButton(true);
            // console.log("changePassword token", token);
            const response = await fetch(`${urlMain}/changePassword`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    currentPassword: oldPassword,
                    newPassword: confirmPassword,
                })
            })
            const data = await response.json();
            setLoadingButton(false);
            if (data.status === true) {
                swal("Success", data.message, "success").then((ok) => {
                    if (ok) {
                        refCloseUpdate.current.click();
                    }
                })
            } else {
                swal("Error", data.message, "error");
            }
        } catch (error) {
            console.error("Error changing password:", error);
            setLoadingButton(false);
        }
    };

    const passText = () => {
        if (!confirmPassword) return;
        setConfirmPasswordText(newPassword === confirmPassword ? "Password matches" : "Passwords do not match");
    };

    useEffect(() => {
        passText();
        // eslint-disable-next-line
    }, [newPassword, confirmPassword]);

    return (
        <>
            <button ref={refOpenUpdate} type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#updatePassword"></button>

            <div className="modal fade" id="updatePassword" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="updatePasswordLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="updatePasswordLabel">Change Password</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form className="my-3">
                                <div className="mb-3">
                                    <label htmlFor="oldPassword" className="form-label">Old Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="oldPassword"
                                        name="oldPassword"
                                        value={oldPassword}
                                        onChange={(e) => { setOldPassword(e.target.value) }}
                                        autoComplete="off"
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="newPassword" className="form-label">New Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="newPassword"
                                        name="newPassword"
                                        minLength="6"
                                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{6,8}$"
                                        value={newPassword}
                                        onChange={(e) => { setNewPassword(e.target.value) }}
                                        autoComplete="off"
                                        required
                                    />
                                </div>
                                <div id="password" className="form-text">
                                    At least 1 Uppercase, 1 Lowercase, 1 Number, 1 Special symbol
                                    from !@#$%^&*_=+-, min 6 characters & max 8 characters.
                                </div>
                                <div className="mt-3 mb-3">
                                    <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        value={confirmPassword}
                                        onChange={(e) => { setConfirmPassword(e.target.value) }}
                                        autoComplete="off"
                                        required
                                    />
                                </div>
                                <div id="confirmPassword" className="form-text">
                                    {confirmPasswordText}
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-dark" data-bs-dismiss="modal" ref={refCloseUpdate}>Close</button>
                            <button type="button" className="btn btn-ff9b44" onClick={handleChangePassword} disabled={oldPassword?.length < 6 || confirmPasswordText !== "Password matches"}>{loadingButton ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminUpdatePassword;
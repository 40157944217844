import React from "react";
import moment from "moment";
import { Chart as ChartJS, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
ChartJS.register(...registerables);

const DashboardMiddleLineChart = ({ dashboardGraph }) => {

    const graph = {
        labels: dashboardGraph?.map((element) => moment(element.date).format("DD-MM")), datasets: [
            {
                label: "Amount",
                data: dashboardGraph?.map((element) => element.purchasePerDay),
                backgroundColor: "#ff9b44",
                borderColor: "#ff9b44",
                borderWidth: 3
            },
            {
                label: "Waste",
                data: dashboardGraph?.map((element) => element.collectedWastePerDay),
                backgroundColor: "#d1929b",
                borderColor: "#d1929b",
                borderWidth: 3
            },
            {
                label: "Emission",
                data: dashboardGraph?.map((element) => element.TotalEmissionPerDay),
                backgroundColor: "#fc6075",
                borderColor: "#fc6075",
                borderWidth: 3
            }
        ]
    };

    const options = { plugins: { title: { display: true, text: "Last 15 Days" } } };

    return (
        <>
            <Line data={graph} options={options} />
        </>
    )
}

export default DashboardMiddleLineChart;
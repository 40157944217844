import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ReactNotificationComponent = ({ title, body }) => {

    const Display = () => (
        <div>
            <h5>{title}</h5>
            <p>{body}</p>
        </div>
    );

    const hideNotification = title === "";

    if (!hideNotification) toast.success(<Display />);

    return (
        <ToastContainer
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false}
            position="top-right"
        />
    )
}

ReactNotificationComponent.defaultProps = {
    title: "This is title",
    body: "Some body"
};

ReactNotificationComponent.propTypes = {
    title: PropTypes.string,
    body: PropTypes.string
};

export default ReactNotificationComponent;
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { refresh, nextPage, previousPage, firstPage, lastPage, setSearchParams } from '../model/usersSlice';
import { useGetUsersListQuery } from '../model/usersSlice';
import { logout } from '../../auth/authSlice';

export function useUsersController() {
    const dispatch = useDispatch();
    const { page, status, name, number } = useSelector(state => state.usersList);

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetUsersListQuery({ page, status, name, number });

    useEffect(() => {
        setSearchParamsLocal({ status, name, number, page });
    }, [status, name, number, page]);

    const [searchParamsLocal, setSearchParamsLocal] = useState({ status, name, number, page });

    const changeSearch = (e) => {
        const { name, value } = e.target;
        setSearchParamsLocal(prev => ({ ...prev, [name]: value }));
    };

    const handleSearch = () => {
        dispatch(setSearchParams({ status: searchParamsLocal.status, name: searchParamsLocal.name.trim(), number: searchParamsLocal.number.trim() }))
    };

    const handleReset = () => {
        dispatch(refresh());
        setSearchParams({ status: '', name: '', number: '' });
    };

    const firstPageClick = () => {
        if (page > 1) {
            dispatch(firstPage())
            // setSearchParamsLocal(pre => ({ ...pre, page: 1 }))
        }
    };
    const lastPageClick = () => {
        if (page < data.totalPages) {
            dispatch(lastPage)
            // setSearchParamsLocal(pre => ({ page: data.totalPages, ...pre }))
        }
    }
    const previousPageClick = () => {
        if (page > 1) {
            // setSearchParamsLocal((pre) => ({ page: pre.page - 1, ...pre }))
            dispatch(previousPage())
        }
    };

    const nextPageClick = () => {
        if (page < data.totalPages) {
            // setSearchParamsLocal((pre) => ({ page: pre.page + 1, ...pre }))
            dispatch(nextPage())
        }
    };

    return {
        searchParamsLocal,
        data,
        isLoading,
        isSuccess,
        isError,
        error,
        changeSearch,
        handleSearch,
        handleReset,
        firstPageClick,
        lastPageClick,
        previousPageClick,
        nextPageClick,
        dispatch,
        logout,
    };
}

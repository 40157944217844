import Avatar from 'react-avatar'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useUsersController } from '../controller/usersController'
import MainBreadcrumb from '../../../components/breadcrumb/MainBreadcrumb'
import SpinnerForTable from '../../../components/spinner/SpinnerForTable'
export default function UsersList(urlMain) {

    const navigate = useNavigate()
    const {
        searchParamsLocal,
        data,
        isLoading,
        isSuccess,
        isError,
        error,
        changeSearch,
        handleSearch,
        handleReset,
        firstPageClick,
        // lastPageClick,
        previousPageClick,
        nextPageClick,
        dispatch,
        logout,
    } = useUsersController();


    if (isError && error.statusCode === '203') {
        dispatch(logout())
    }
    return (
        <>

            {isError && <div>Error:{error}</div>}

            <div className="container-fluid mt-5">
                <div className="row">
                    <div className="col-md-6 text-left">
                        <h3 className="page-title">Users</h3>
                        <ul className="breadcrumb">
                            <MainBreadcrumb />
                            <li className="breadcrumb-item">
                                <span className="breadcrumb-active">Users</span>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-6 text-right">
                        <a href={`${urlMain}/downloadUserData`} download>
                            <button type="button" className="btn btn-ff9b44 btn-round"><i className="fa fa-download" aria-hidden="true"></i> Download Users Data</button>
                        </a>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-3" >
                        <div className="card">
                            <div className="card-body text-center status-info">
                                <h6>Total user</h6>
                                {isSuccess && <h4>{data.totalUsers}</h4>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-2">
                        <div className="form-floating">
                            <select className="form-select" id="status" name="status" value={searchParamsLocal.status} onChange={changeSearch}>
                                <option value="">---Select---</option>
                                <option value="0">Active</option>
                                <option value="1">Inactive</option>
                            </select>
                            <label htmlFor="status" className="mb-3">Status</label>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="form-floating">
                            <input type="text" className="form-control" id="name" name="name" placeholder="Search" autoComplete="off" value={searchParamsLocal.name} onChange={changeSearch} />
                            <label htmlFor="name" className="mb-3">Name</label>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="form-floating">
                            <input type="text" className="form-control" id="number" name="number" placeholder="Search" autoComplete="off" value={searchParamsLocal.number} onChange={changeSearch} />
                            <label htmlFor="xyz" className="mb-3">Number</label>
                        </div>
                    </div>
                    <div className="col-md-1 pt-1">
                        <button type="button" className="btn btn-55ce63 btn-lg me-1" onClick={handleSearch}>
                            Search
                        </button>
                    </div>
                    <div className="col-md-1 pt-1">
                        <button type="reset" className="btn btn-55ce63 btn-lg" onClick={handleReset}>
                            Refresh
                        </button>
                    </div>
                </div>
                <div className="card card-dark mt-4">
                    {isLoading && <SpinnerForTable />}
                    {isSuccess && data?.users?.length === 0 &&
                        <div className="text-center mt-5 mb-5 fw-bold">No users found at the moment!</div>}

                    {isSuccess && data?.users?.length > 0 && <div className="card-block table-border-style">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr className="text-left">
                                        <th scope="col"></th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Number</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Joined Date</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data?.users?.map((element, index) => {
                                            return (
                                                <tr className="text-left fw-normal cp" key={index} onClick={() => {
                                                    navigate("/main:usersDetails", { state: element })
                                                }}>
                                                    <td><Avatar round={true} size="35" name={element.Name ? element.Name : element.Name === "" ? "Unkown Person" : ""} /></td>
                                                    <td className="fw-bold">{element.Name ? element.Name : element.Name === "" ? "Unkown Person" : ""}<div className="fw-lighter text-muted">{element.PropertyName}</div></td>
                                                    <td>{element.Number}</td>
                                                    <td>{element.IsAdmin === 0 ? <span className="badge bg-dark">Default</span> : element.IsAdmin === 1 ? <span className="badge bg-primary">Primary</span> : element.IsAdmin === 2 ? <span className="badge bg-secondary">Secondary</span> : ""}</td>
                                                    <td>{moment(element.CreationTime).format("DD/MM/YYYY | hh:mm A").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" })}</td>
                                                    <td>{element.BlockedStatus === "0" ? <i className="fa-solid fa-circle text-success"></i> : element.BlockedStatus === "1" ? <i className="fa-solid fa-circle text-danger"></i> : ""}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="container mt-3 mb-3" id="paginationnumbers">
                            <div className="pagination" id="paginationnumbers">
                                <div className="page-item text-left">
                                    <button className="page-link" disabled={searchParamsLocal.page === '1'} onClick={firstPageClick}>
                                        1
                                    </button>
                                </div>
                                <div className="page-item text-left">
                                    <button className="page-link" disabled={searchParamsLocal.page === '1'} onClick={previousPageClick}>
                                        &laquo; Previous
                                    </button>
                                </div>
                                <div className="page-item">
                                    <div className="page-link">
                                        {searchParamsLocal.page} of {data?.totalPages}
                                    </div>
                                </div>
                                <div className="page-item text-left">
                                    <button className="page-link" disabled={searchParamsLocal.page === data?.totalPages} onClick={nextPageClick}>
                                        Next &raquo;
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>

        </>
    )
}
import React from "react";

const DashboardBottomCard = ({ dashboardData }) => {
    return (
        <>
            <div className="row mt-5">
                <div className="col-md-3">
                    <div className="card card-dark widget-card-1">
                        <div className="card-block-small status-info">
                            <i className="ti-user bg-ff9b44 card1-icon"></i>
                            <h6>Total Departments</h6>
                            <h4>{dashboardData.length === 0 ? 0 : dashboardData[14][0]?.totalDepartments}</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card card-dark widget-card-1">
                        <div className="card-block-small status-info">
                            <i className="fas fa-hotel bg-ff9b44 card1-icon"></i>
                            <h6>Total Designations</h6>
                            <h4>{dashboardData.length === 0 ? 0 : dashboardData[15][0]?.totalDesignation}</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card card-dark widget-card-1">
                        <div className="card-block-small status-info">
                            <i className="ti-trash bg-ff9b44 card1-icon"></i>
                            <h6>Total Roles</h6>
                            <h4>{dashboardData.length === 0 ? 0 : dashboardData[16][0]?.totalRoles}</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card card-dark widget-card-1">
                        <div className="card-block-small status-info">
                            <i className="ti-truck bg-ff9b44 card1-icon"></i>
                            <h6>Total Employees</h6>
                            <h4>{dashboardData.length === 0 ? 0 : dashboardData[17][0]?.totalEmployees}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardBottomCard;
import firebase from "firebase/app";
import "firebase/messaging";
import secureLocalStorage from "react-secure-storage";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

const publicKey = process.env.REACT_APP_VAPID_KEY;

const getToken = async (setTokenFound) => {
    try {
        const currentToken = await messaging.getToken({ vapidKey: publicKey });
        setTokenFound(!!currentToken);
        secureLocalStorage.setItem("fcm-token", currentToken || "");
        return currentToken || "";
    } catch (error) {
        console.error("An error occurred while retrieving token.", error);
        return "";
    }
};

const onMessageListener = () =>
    new Promise((resolve) => {
        messaging.onMessage((payload) => {
            resolve(payload);
        })
    });

export { getToken, onMessageListener };
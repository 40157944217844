import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Avatar from "react-avatar";
import useLoadingPage from "../../../../hooks/useLoadingPage";
import SpinnerForPage from "../../../../components/spinner/SpinnerForPage";
import SpinnerForTable from "../../../../components/spinner/SpinnerForTable";
import MainBreadcrumb from "../../../../components/breadcrumb/MainBreadcrumb";

const PickupsHistory = ({ urlMain, token, pickupsHistoryData, setPickupsHistoryData, pickupsHistorySearch, setPickupsHistorySearch, page, setPage }) => {

    const loadingPage = useLoadingPage();
    const [loadingTable, setLoadingTable] = useState(true);
    const navigate = useNavigate();

    const [driverOption, setDriverOption] = useState([])
    const firstPage = () => setPage(1);
    const pageMin = () => setPage((prevPage) => Math.max(prevPage - 1));
    const pageAdd = () => setPage((prevPage) => Math.min(prevPage + 1));

    console.log(driverOption);
    const getDriverApi = async () => {
        try {
            const response = await fetch(`${urlMain}/drivers`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                }
            })
            const data = await response.json(response)
            if (data.status === true) {
                setDriverOption(data.data);
            }
        } catch (error) {
            console.error("Error in getDriverApi:", error);
        }
    };

    const getPickupsHistoryApi = async () => {
        try {
            setLoadingTable(true);
            const response = await fetch(`${urlMain}/pickup/history?page=${page}&status=${pickupsHistorySearch.status}&propertyName=${pickupsHistorySearch.propertyName}&fromDate=${pickupsHistorySearch.fromDate}&toDate=${pickupsHistorySearch.toDate}&minPrice=${pickupsHistorySearch.priceMin}&maxPrice=${pickupsHistorySearch.priceMax}&driverId=${pickupsHistorySearch.driverId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })
            const data = await response.json();
            if (data.status === true) {
                setPickupsHistoryData(data.data);
                setLoadingTable(false);
            }
        } catch (error) {
            console.error("Error fetching pickup history:", error);
        }
    };

    const changeSearch = (e) => setPickupsHistorySearch({ ...pickupsHistorySearch, [e.target.name]: e.target.value });
    const handleReset = () => window.location.reload();

    useEffect(() => {
        getDriverApi()
        document.title = "Pickups History - Admin Panel | CRM";
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getPickupsHistoryApi();
        // eslint-disable-next-line
    }, [page]);

    return (
        <>
            {
                loadingPage ? <SpinnerForPage /> :
                    <div className="container-fluid mt-5">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="page-title">Pickups History</h3>
                                <ul className="breadcrumb">
                                    <MainBreadcrumb />
                                    <li className="breadcrumb-item">
                                        <span className="breadcrumb-active">Pickups History</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row mt-4">
                            {
                                pickupsHistoryData?.cardData?.map((element, index) => {
                                    return (
                                        <div className="col-md-3" key={index}>
                                            <div className="card">
                                                <div className="card-body text-center status-info">
                                                    <h6>{element.cardData}</h6>
                                                    <h4>{element.TotalPickups}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="row d-flex justify-content-center flex-wrap m-1">
                            <div className="col-md-2 me-2 ms-2" >
                                <div className="form-floating">
                                    <select className="form-select" id="status" name="status" value={pickupsHistorySearch.status} onChange={changeSearch}>
                                        <option value="">---Select---</option>
                                        <option value="5">Completed</option>
                                        <option value="6">Cancelled</option>
                                        <option value="7">Empty</option>
                                        <option value="8">Mixed Waste</option>
                                    </select>
                                    <label htmlFor="status" className="mb-3">Status</label>
                                </div>
                            </div>
                            <div className="col-md-2 me-2 ms-2">
                                <div className="form-floating">
                                    <input type="text" className="form-control" id="propertyName" name="propertyName" placeholder="Search" autoComplete="off" value={pickupsHistorySearch.propertyName} onChange={changeSearch} />
                                    <label htmlFor="propertyName" className="mb-3">Property Name</label>
                                </div>
                            </div>
                            <div className="col-md-2 me-2 ms-2">
                                <div className="form-floating">
                                    <input type="date" className="form-control" id="fromDate" name="fromDate" placeholder="Search" autoComplete="off" max={moment(new Date()).format("YYYY-MM-DD")} value={pickupsHistorySearch.fromDate} onChange={changeSearch} />
                                    <label htmlFor="fromDate" className="mb-3">Start Date</label>
                                </div>
                            </div>
                            <div className="col-md-2 me-2 ms-2">
                                <div className="form-floating">
                                    <input type="date" className="form-control" id="toDate" name="toDate" placeholder="Search" autoComplete="off" min={pickupsHistorySearch.fromDate} max={moment(new Date()).format("YYYY-MM-DD")} value={pickupsHistorySearch.toDate} onChange={changeSearch} />
                                    <label htmlFor="toDate" className="mb-3">End Date</label>
                                </div>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center flex-wrap mt-3">
                            <div className="col-md-2 me-2 ms-2" >
                                <div className="form-floating">
                                    <select className="form-select" id="driverId" name="driverId" value={pickupsHistorySearch.driverId} onChange={changeSearch}>
                                        <option value="">---Select---</option>
                                        {
                                            Array.isArray(driverOption) && driverOption.map(item => <option value={item.DriverId}>{item.DriverName}</option>)
                                        }
                                    </select>
                                    <label htmlFor="driverId" className="mb-3">Driver</label>
                                </div>
                            </div>
                            <div className="col-md-2 me-2 ms-2">
                                <div className="form-floating">
                                    <input type="number" className="form-control" id="priceMin" name="priceMin" placeholder="Search" autoComplete="off" value={pickupsHistorySearch.priceMin} onChange={changeSearch} />
                                    <label htmlFor="priceMin" className="mb-3">Price (min)</label>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-floating">
                                    <input type="number" className="form-control" id="priceMax" name="priceMax" placeholder="Search" autoComplete="off" value={pickupsHistorySearch.priceMax} onChange={changeSearch} />
                                    <label htmlFor="priceMax" className="mb-3">Price (max)</label>
                                </div>
                            </div>
                            <div className="col-md-2  d-flex">
                                <button type="button" className="btn btn-55ce63 btn-lg me-1" onClick={() => {
                                    getPickupsHistoryApi();
                                    setPage(1);
                                }}>Search</button>
                                <button type="reset" className="btn btn-55ce63 btn-lg" onClick={handleReset}>Reset</button>
                            </div>

                        </div>
                        <div className="card card-dark mt-4">
                            {
                                loadingTable ? <SpinnerForTable /> : pickupsHistoryData?.data?.length === 0 ? (
                                    <div className="text-center mt-5 mb-5 fw-bolder">No pickups found at the moment!</div>
                                ) : (
                                    <div className="card-block table-border-style">
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr className="text-left">
                                                        <th scope="col"></th>
                                                        <th scope="col">Property Name</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Driver Name</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Weight</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Pickup Rating</th>
                                                        <th scope="col">Pickup/Cancel Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        pickupsHistoryData?.data?.map((element, index) => {
                                                            return (
                                                                <tr className="text-left fw-normal cp" key={index} onClick={() => {
                                                                    navigate("/main:pickupsHistoryDetails", { state: element })
                                                                }}>
                                                                    <td><Avatar round={true} size="35" name={element.PropertyName} /></td>
                                                                    <td className="fw-bold">{element.PropertyName}<div className="fw-lighter text-muted">{element.Address.split(",")[element.Address.split(",").length - 4]}</div></td>
                                                                    <td>{element.ContactPerson}<div className="fw-lighter text-muted">{element.ContactNumber}</div></td>
                                                                    <td>{element.DriverName}<div className="fw-lighter text-muted">{element.DriverNumber}</div></td>
                                                                    <td>{element.PickupStatus === "5" ? <span className="badge bg-success">Completed</span> : element.PickupStatus === "6" ? <span className="badge bg-danger">Cancelled</span> : element.PickupStatus === "7" ? <span className="badge bg-warning">Empty</span> : element.PickupStatus === "8" ? <span className="badge bg-dark">Mixed Waste</span> : ""}</td>
                                                                    <td>{element.TotalWeigth === "0" ? "NA" : (Number(element.TotalWeigth).toFixed(2)) + " Kg"}</td>
                                                                    <td>{element.TotalAmount === "0" ? "NA" : "₹ " + element.TotalAmount}</td>
                                                                    <td>{element.average_rating === null ? "NA" : element.average_rating} <i className="fa fa-star checked text-warning"></i></td>
                                                                    <td>{element.PickupStatus === "6" ? moment(element.CancelledTime).format("DD/MM/YYYY | hh:mm A").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" }) : moment(element.PickupTime).format("DD/MM/YYYY | hh:mm A").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" })}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="container mt-3 mb-3" id="paginationnumbers">
                                            <div className="pagination" id="paginationnumbers">
                                                <div className="page-item text-start">
                                                    <button className="page-link" disabled={page <= 1} onClick={firstPage}>
                                                        1
                                                    </button>
                                                </div>
                                                <div className="page-item text-left">
                                                    <button className="page-link" disabled={page <= 1} onClick={pageMin}>
                                                        &laquo; Previous
                                                    </button>
                                                </div>
                                                <div className="page-item">
                                                    <div className="page-link">
                                                        {page} of {pickupsHistoryData?.totalPages}
                                                    </div>
                                                </div>
                                                <div className="page-item text-left">
                                                    <button className="page-link" disabled={page === pickupsHistoryData?.totalPages} onClick={pageAdd}>
                                                        Next &raquo;
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </div>
                    </div>
            }
        </>
    )
}

export default PickupsHistory;
import React, { useState, lazy, Suspense, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import Notification from "./pages/Notification";
import "./App.css";

//loader
import Loader from './components/spinner/SpinnerForPage'
// Authentication/State
import AdminSignin from "./auth/AdminSignin";
import B2GSignin from "./auth/B2GSignin";
import CrmState from "./context/CrmState";
import { tokenNotFound, verifyToken } from "./features/auth/authSlice";

//Error boundaries
import ErrorBoundary from './ErrorBoundary'
// Navbar
import LayoutB2B from "./components/layout/LayoutB2b";
import LayoutB2g from "./components/layout/LayoutB2g";

// Dashboard
import Dashboard from "./pages/admin/dashboard/dashboard/Dashboard";

// Common
// import Users from "./pages/admin/main/users/Users";
import UsersList from './features/b2b_features/views/usersListView'
import Properties from "./pages/admin/main/properties/Properties";
import CurrentPickups from "./pages/admin/main/pickups/CurrentPickups";
import PickupsHistory from "./pages/admin/main/pickups/PickupsHistory";
import TransactionsHistory from "./pages/admin/main/transactionsHistory/TransactionsHistory";
import GeofenceTable from "./features/wards/geofanceTable";
import AddGeofence from "./features/wards/addGeofence";

//lazy loaded component 
//common
const GovermentDashboard = lazy(() => import('./pages/admin/dashboard/goverment/GovermentDashboard'))
const AdminDashboard = lazy(() => import('./pages/admin/dashboard/admin/AdminDashboard'))
const SuperCrmDashboard = lazy(() => import('./pages/admin/dashboard/superCrm/SuperCrmDashboard'))
const UsersDetails = lazy(() => import('./pages/admin/main/users/UsersDetails'))
const PropertiesDetails = lazy(() => import('./pages/admin/main/properties/PropertiesDetails'))
const PropertiesPickups = lazy(() => import('./pages/admin/main/properties/PropertiesPickups'))
const Dustbins = lazy(() => import('./pages/admin/main/properties/Dustbins'))
const DustbinsDetails = lazy(() => import('./pages/admin/main/properties/DustbinsDetails'))
const CurrentPickupsDetails = lazy(() => import('./pages/admin/main/pickups/CurrentPickupsDetails'))
const TransferCurrentPickups = lazy(() => import('./pages/admin/main/pickups/TransferCurrentPickups'))
const VerifyCurrentPickups = lazy(() => import('./pages/admin/main/pickups/VerifyCurrentPickups'))
const PickupsHistoryDetails = lazy(() => import('./pages/admin/main/pickups/PickupsHistoryDetails'))
const DriverPickups = lazy(() => import('./pages/admin/main/driverPickups/DriverPickups'))
const CategoryList = lazy(() => import('./pages/admin/main/rateList/CategoryList'))
const SubCategoryList = lazy(() => import('./pages/admin/main/rateList/SubCategoryList'))
const TransactionsHistoryDetails = lazy(() => import('./pages/admin/main/transactionsHistory/TransactionsHistoryDetails'))
const Holidays = lazy(() => import('./pages/admin/main/holidays/Holidays'))
const OpenQueries = lazy(() => import('./pages/admin/main/complian/OpenQueries'))
const OpenQueriesDetails = lazy(() => import('./pages/admin/main/complian/OpenQueriesDetails'))
const OpenQueriesHistory = lazy(() => import('./pages/admin/main/complian/OpenQueriesHistory'))
const Queries = lazy(() => import('./pages/admin/main/complian/Queries'))
const Profile = lazy(() => import('./pages/admin/main/profile/Profile'))

// Super Admin
const Banks = lazy(() => import('./pages/admin/superAdmin/bank/Banks'))
const UsersBank = lazy(() => import('./pages/admin/superAdmin/bank/UsersBank'))
const UsersBankDetails = lazy(() => import('./pages/admin/superAdmin/bank/UsersBankDetails'))
const UsersUpi = lazy(() => import('./pages/admin/superAdmin/upi/UsersUpi'))
const UserUpiDetails = lazy(() => import('./pages/admin/superAdmin/upi/UserUpiDetails'))
const FundSource = lazy(() => import('./pages/admin/superAdmin/fundSource/FundSource'))
const CrmUsers = lazy(() => import('./pages/admin/superAdmin/CrmUsers'))
const Logs = lazy(() => import('./pages/admin/superAdmin/Logs'))
const LogsDetails = lazy(() => import('./pages/admin/superAdmin/LogsDetails'))
const DeleteAccount = lazy(() => import('./pages/admin/superAdmin/deleteAccount/DeleteAccount'))
const DeleteAccountDetails = lazy(() => import('./pages/admin/superAdmin/deleteAccount/DeleteAccountDetails'))
const CrmReasons = lazy(() => import('./pages/admin/superCrm/reasons/CrmReasons'))
const PickupStatus = lazy(() => import('./pages/admin/superCrm/pickupStatus/PickupStatus'))
const WasteType = lazy(() => import('./pages/admin/superCrm/wasteType/WasteType'))
const PaymentMode = lazy(() => import('./pages/admin/superCrm/paymentMode/PaymentMode'))

// Admin
const Drivers = lazy(() => import('./pages/admin/admin/driver/Drivers'))
const DriversDetails = lazy(() => import('./pages/admin/admin/driver/DriversDetails'))
const AssignVehicleToDrivers = lazy(() => import('./pages/admin/admin/driver/AssignVehicleToDrivers'))
const Blogs = lazy(() => import('./pages/admin/admin/blogs/Blogs'))
const BlogsDetails = lazy(() => import('./pages/admin/admin/blogs/BlogsDetails'))
const AddBlogs = lazy(() => import('./pages/admin/admin/blogs/AddBlogs'))
const UpdateBlogs = lazy(() => import('./pages/admin/admin/blogs/UpdateBlogs'))
const ContactUs = lazy(() => import('./pages/admin/admin/contactUs/ContactUs'))
const ContactUsDetails = lazy(() => import('./pages/admin/admin/contactUs/ContactUsDetails'))
const Nodes = lazy(() => import('./pages/admin/admin/godown/Nodes'))
const Pickups = lazy(() => import('./pages/admin/admin/godown/Pickups'))
const PickupsEntry = lazy(() => import('./pages/admin/admin/godown/PickupEntry.js/PickupsEntry'))
const OfflineDeals = lazy(() => import('./pages/admin/admin/offlineDeal/OfflineDeals'))
const Area = lazy(() => import('./pages/admin/admin/others/areas/Area'))
const Reason = lazy(() => import('./pages/admin/admin/others/reasons/Reason'))
const Promotions = lazy(() => import('./pages/admin/admin/others/promotionImage/Promotions'))
const Vehicle = lazy(() => import('./pages/admin/admin/others/vehicle/Vehicle'))


// HR
const Departments = lazy(() => import('./pages/admin/hr/departments/Departments'))
const Designations = lazy(() => import('./pages/admin/hr/designations/Designations'))
const Roles = lazy(() => import('./pages/admin/hr/roles/Roles'))
const Employees = lazy(() => import('./pages/admin/hr/employees/Employees'))
const EmployeesDetails = lazy(() => import('./pages/admin/hr/employees/EmployeesDetails'))
const AddEmployee = lazy(() => import('./pages/admin/hr/employees/AddEmployee'))
const EmployeePermission = lazy(() => import('./pages/admin/hr/employees/EmployeePermission'))

// Gov 
const GovProperties = lazy(() => import('./pages/admin/gov/GovProperties'))

// B2G
const States = lazy(() => import('./features/states/stateList'))
const District = lazy(() => import('./features/districts/districtList'))
const Communities = lazy(() => import('./features/communities/communityList'))
const Wards = lazy(() => import('./features/wards/wardsList'))
const ViewWardMap = lazy(() => import('./features/wards/viewWardMap'))
const EditWardMap = lazy(() => import('./features/wards/editWardMap'))
// const DriverB2g = lazy(() => import('./features/mvvm/b2g/view/driverB2g/driverB2g'))
const B2gAdmin = lazy(() => import('./features/b2g_admin/adminList'))
const CommunityAdmin = lazy(() => import('./features/community_admin/communityAdminList'))
const B2GProfile = lazy(() => import('./features/profile/profile'))
const LiveMap = lazy(() => import('./features/driverLive/liveDriver'))
const SurveyProperties = lazy(() => import('./features/surveyProperties/surveyProperties'))
const SurveyProperty = lazy(() => import('./features/surveyProperties/surveyProperty'))
const SurveyedPropertiesMap = lazy(() => import('./features/b2gdashboard/surveyedPropertiesMap'))
// const survey = lazy(() => import('../src/features/b2g_features/views/propertiesList'))
// const surveyDetails = lazy(() => import('./pages/b2g/survey/SurveyDetails'))
const App = () => {

    const urlMain = process.env.REACT_APP_URL_MAIN;
    const urlAdmin = process.env.REACT_APP_URL_ADMIN;
    const urlCrm = process.env.REACT_APP_URL_CRM;
    const urlHR = process.env.REACT_APP_URL_HR;
    const urlGov = process.env.REACT_APP_URL_GOV;
    const urlSuperAdmin = process.env.REACT_APP_URL_SUPER_ADMIN;
    const urlB2G = process.env.REACT_APP_URL_B2G;

    const [page, setPage] = useState(1);

    // Users
    // const [usersData, setUsersData] = useState([]);
    // const [usersSearch, setUsersSearch] = useState({ status: "", name: "", number: "" });

    // Properties
    const [propertiesData, setPropertiesData] = useState([]);
    const [propertiesSearch, setpropertiesSearch] = useState({ type: "", name: "", number: "", startDate: "", endDate: "" });

    // Dustbins
    const [dustbinData, setDustbinData] = useState([]);
    const [dustbinsSearch, setDustbinsSearch] = useState({ status: "", name: "", startDate: "", endDate: "" });

    // Current Pickups
    const [currentPickupsData, setCurrentPickupsData] = useState([]);
    const [currentPickupsSearch, setCurrentPickupsSearch] = useState({ status: "", propertyName: "", startDate: "", endDate: "", page: 1 });

    // Pickups History
    const [pickupsHistoryData, setPickupsHistoryData] = useState([]);
    const [pickupsHistorySearch, setPickupsHistorySearch] = useState({ status: "", propertyName: "", fromDate: "", toDate: "", priceMin: "", priceMax: "", driverId: "" });

    // Transactions History
    const [transactionsHistoryData, setTransactionsHistoryData] = useState([]);
    const [transactionsHistorySearch, setTransactionsHistorySearch] = useState({ status: "", payout: "", ecowrap: "", challan: "" });

    // Drivers
    const [driversData, setDriversData] = useState([]);
    const [driversSearch, setDriversSearch] = useState({ status: "", page: 1 });

    //geofancing
    const [wardData, setWardData] = useState({})
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { panelType, adminRole, token, isAuthenticated } = useSelector(state => state.auth)

    useEffect(() => {
        if (isAuthenticated === null && panelType === 'b2b' && token !== null) {
            dispatch(verifyToken(token));
        } else if (isAuthenticated === null && panelType === 'b2b' && token === null) {
            dispatch(tokenNotFound());
        }
        else if (isAuthenticated === false && panelType === 'b2b' && location.pathname !== '/b2b:login') {
            navigate('/b2b:login');
        } else if (isAuthenticated === true && panelType === 'b2b' && location.pathname === '/b2b:login') {
            navigate('/')
        } else if (isAuthenticated === null && panelType === 'b2g' && token === null) {
            dispatch(tokenNotFound());
        } else if (isAuthenticated === null && panelType === 'b2g' && token !== null) {
            dispatch(verifyToken(token));
        } else if (isAuthenticated === false && panelType === 'b2g' && location.pathname !== '/b2g/login') {
            navigate('/b2g/login')
        } else if (isAuthenticated === true && panelType === 'b2g' && location.pathname === '/b2g/login') {
            navigate('/')
        }
    }, [isAuthenticated, token, panelType, location, dispatch, navigate]);
    return (
        <>
            <ErrorBoundary>
                {
                    (panelType === "b2b") &&
                    <CrmState urlAdmin={urlAdmin} urlCrm={urlCrm} urlHR={urlHR} token={token}>
                        {/* <Notification /> */}
                        {/* <AdminNavbar urlMain={urlMain} urlGov={urlGov} token={token} role={role} /> */}
                        {/* <AdminSidebar urlMain={urlMain} token={token} role={role} /> */}
                        <Routes>
                            {<Route path="/b2b:login" element={<AdminSignin urlMain={urlMain} />} ></Route>}
                            {isAuthenticated === null && <Route path='/' element={<Loader />} />}
                            {isAuthenticated === true && <Route path="/" element={<LayoutB2B urlMain={urlMain} token={token} adminRole={adminRole} />}>
                                {/* Dashboard Route Start */}
                                {adminRole !== '8' && <Route index element={<Dashboard urlMain={urlMain} token={token} />}></Route>}
                                {adminRole === '8' && <Route index element={<Suspense fallback={<Loader />}><Properties urlMain={urlMain} token={token} propertiesData={propertiesData} setPropertiesData={setPropertiesData} /></Suspense>}></Route>}
                                <Route exact path="dashboard:adminDashboard" element={<Suspense fallback={<Loader />}><AdminDashboard urlAdmin={urlAdmin} token={token} /></Suspense>}></Route>
                                <Route exact path="dashboard:govermentDashboard" element={<Suspense fallback={<Loader />}><GovermentDashboard token={token} urlGov={urlGov} /></Suspense>}></Route>
                                <Route exact path="dashboard:superCrmDashboard" element={<Suspense fallback={<Loader />}><SuperCrmDashboard token={token} urlCrm={urlCrm} /></Suspense>}></Route>
                                {/* Dashboard Route End */}

                                {/* Common Route Start */}
                                {/* <Route exact path="main:users" element={<Users urlMain={urlMain} token={token} usersData={usersData} setUsersData={setUsersData} usersSearch={usersSearch} setUsersSearch={setUsersSearch} page={page} setPage={setPage} />}></Route> */}
                                <Route exact path="main:users" element={<UsersList urlMain={urlMain} />}></Route>
                                <Route exact path="main:usersDetails" element={<Suspense fallback={<Loader />}><UsersDetails urlMain={urlMain} token={token} /></Suspense>}></Route>
                                <Route exact path="main:allProperties" element={<Properties urlMain={urlMain} token={token} urlCrm={urlCrm} propertiesData={propertiesData} setPropertiesData={setPropertiesData} propertiesSearch={propertiesSearch} setpropertiesSearch={setpropertiesSearch} page={page} setPage={setPage} />}></Route>
                                <Route exact path="main:allPropertiesDetails" element={<Suspense fallback={<Loader />}><PropertiesDetails urlMain={urlMain} token={token} /></Suspense>}></Route>
                                <Route exact path="main:allPropertiesPickups" element={<Suspense fallback={<Loader />}><PropertiesPickups urlMain={urlMain} token={token} /></Suspense>}></Route>
                                <Route exact path="main:dustbins" element={<Suspense fallback={<Loader />}><Dustbins urlMain={urlMain} token={token} dustbinData={dustbinData} setDustbinData={setDustbinData} dustbinsSearch={dustbinsSearch} setDustbinsSearch={setDustbinsSearch} page={page} setPage={setPage} /></Suspense>}></Route>
                                <Route exact path="main:dustbinsDetails" element={<Suspense fallback={<Loader />}><DustbinsDetails urlMain={urlMain} token={token} /></Suspense>}></Route>
                                <Route exact path="main:currentPickups" element={<CurrentPickups urlMain={urlMain} token={token} currentPickupsData={currentPickupsData} setCurrentPickupsData={setCurrentPickupsData} currentPickupsSearch={currentPickupsSearch} setCurrentPickupsSearch={setCurrentPickupsSearch} />}></Route>
                                <Route exact path="main:currentPickupsDetails" element={<Suspense fallback={<Loader />}><CurrentPickupsDetails urlMain={urlMain} urlAdmin={urlAdmin} token={token} /></Suspense>}></Route>
                                <Route exact path="main:transferCurrentPickups" element={<Suspense fallback={<Loader />}><TransferCurrentPickups urlMain={urlMain} token={token} /></Suspense>}></Route>
                                <Route exact path="main:verifyCurrentPickups" element={<Suspense fallback={<Loader />}><VerifyCurrentPickups urlMain={urlMain} token={token} /></Suspense>}></Route>
                                <Route exact path="main:pickupsHistory" element={<PickupsHistory urlMain={urlMain} token={token} pickupsHistoryData={pickupsHistoryData} setPickupsHistoryData={setPickupsHistoryData} pickupsHistorySearch={pickupsHistorySearch} setPickupsHistorySearch={setPickupsHistorySearch} page={page} setPage={setPage} />}></Route>
                                <Route exact path="main:pickupsHistoryDetails" element={<Suspense fallback={<Loader />}><PickupsHistoryDetails urlMain={urlMain} token={token} /></Suspense>}></Route>
                                <Route exact path="main:driverPickups" element={<Suspense fallback={<Loader />}><DriverPickups urlMain={urlMain} token={token} /></Suspense>}></Route>
                                <Route exact path="main:categoryList" element={<Suspense fallback={<Loader />}><CategoryList urlMain={urlMain} urlAdmin={urlAdmin} token={token} /></Suspense>}></Route>
                                <Route exact path="main:subCategoryList" element={<Suspense fallback={<Loader />}><SubCategoryList urlMain={urlMain} urlAdmin={urlAdmin} token={token} /></Suspense>}></Route>
                                <Route exact path="main:transactionsHistory" element={<Suspense fallback={<Loader />}><TransactionsHistory urlMain={urlMain} token={token} transactionsHistoryData={transactionsHistoryData} setTransactionsHistoryData={setTransactionsHistoryData} transactionsHistorySearch={transactionsHistorySearch} setTransactionsHistorySearch={setTransactionsHistorySearch} page={page} setPage={setPage} /></Suspense>}></Route>
                                <Route exact path="main:transactionsHistoryDetails" element={<Suspense fallback={<Loader />}><TransactionsHistoryDetails urlMain={urlMain} token={token} /></Suspense>}></Route>
                                <Route exact path="main:holidays" element={<Suspense fallback={<Loader />}><Holidays urlMain={urlMain} urlSuperAdmin={urlSuperAdmin} token={token} /></Suspense>}></Route>
                                <Route exact path="main:openQueries" element={<Suspense fallback={<Loader />}><OpenQueries urlMain={urlMain} token={token} /></Suspense>}></Route>
                                <Route exact path="main:openQueriesDetails" element={<Suspense fallback={<Loader />}><OpenQueriesDetails urlMain={urlMain} token={token} /></Suspense>}></Route>
                                <Route exact path="main:openQueriesHistory" element={<Suspense fallback={<Loader />}><OpenQueriesHistory urlMain={urlMain} token={token} /></Suspense>}></Route>
                                <Route exact path="main:queries" element={<Suspense fallback={<Loader />}><Queries urlMain={urlMain} token={token} /></Suspense>}></Route>
                                <Route exact path="main:profile" element={<Suspense fallback={<Loader />}><Profile urlMain={urlMain} token={token} /></Suspense>}></Route>
                                {/* Common Route End */}

                                {/* Super Admin Route Start */}
                                <Route exact path="superAdmin:banks" element={<Suspense fallback={<Loader />}><Banks urlSuperAdmin={urlSuperAdmin} token={token} /></Suspense>}></Route>
                                <Route exact path="superAdmin:usersBank" element={<Suspense fallback={<Loader />}><UsersBank urlSuperAdmin={urlSuperAdmin} token={token} /></Suspense>}></Route>
                                <Route exact path="superAdmin:userBankDetails" element={<Suspense fallback={<Loader />}><UsersBankDetails urlSuperAdmin={urlSuperAdmin} token={token} /></Suspense>}></Route>
                                <Route exact path="superAdmin:usersUpi" element={<Suspense fallback={<Loader />}><UsersUpi urlSuperAdmin={urlSuperAdmin} token={token} /></Suspense>}></Route>
                                <Route exact path="superAdmin:usersUpiDetails" element={<Suspense fallback={<Loader />}><UserUpiDetails urlSuperAdmin={urlSuperAdmin} token={token} /></Suspense>}></Route>
                                <Route exact path="superAdmin:fundSource" element={<Suspense fallback={<Loader />}><FundSource urlSuperAdmin={urlSuperAdmin} token={token} /></Suspense>}></Route>
                                <Route exact path="superAdmin:crmUsers" element={<Suspense fallback={<Loader />}><CrmUsers urlSuperAdmin={urlSuperAdmin} token={token} /></Suspense>}></Route>
                                <Route exact path="superAdmin:logs" element={<Suspense fallback={<Loader />}><Logs urlSuperAdmin={urlSuperAdmin} token={token} /></Suspense>}></Route>
                                <Route exact path="superAdmin:logsDetails" element={<Suspense fallback={<Loader />}><LogsDetails /></Suspense>}></Route>
                                <Route exact path="superAdmin:deletAccount" element={<Suspense fallback={<Loader />}><DeleteAccount urlSuperAdmin={urlSuperAdmin} token={token} /></Suspense>}></Route>
                                <Route exact path="superAdmin:deletAccountDetails" element={<Suspense fallback={<Loader />}><DeleteAccountDetails urlSuperAdmin={urlSuperAdmin} token={token} /></Suspense>}></Route>
                                {/* Super Admin Route End */}

                                {/* ADMIN Route Start */}
                                <Route exact path="admin:drivers" element={<Suspense fallback={<Loader />}><Drivers urlAdmin={urlAdmin} token={token} driversData={driversData} setDriversData={setDriversData} driversSearch={driversSearch} setDriversSearch={setDriversSearch} /></Suspense>}></Route>
                                <Route exact path="admin:driversDetails" element={<Suspense fallback={<Loader />}><DriversDetails urlAdmin={urlAdmin} token={token} /></Suspense>}></Route>
                                <Route exact path="admin:assignVehicleToDrivers" element={<Suspense fallback={<Loader />}><AssignVehicleToDrivers urlAdmin={urlAdmin} token={token} /></Suspense>}></Route>
                                <Route exact path="admin:blogs" element={<Suspense fallback={<Loader />}><Blogs urlAdmin={urlAdmin} token={token} /></Suspense>}></Route>
                                <Route exact path="admin:blogsDetails" element={<Suspense fallback={<Loader />}><BlogsDetails /></Suspense>}></Route>
                                <Route exact path="admin:addBlogs" element={<Suspense fallback={<Loader />}><AddBlogs urlAdmin={urlAdmin} token={token} /></Suspense>}></Route>
                                <Route exact path="admin:updateBlogs" element={<Suspense fallback={<Loader />}><UpdateBlogs urlAdmin={urlAdmin} token={token} /></Suspense>}></Route>
                                <Route exact path="admin:contactUs" element={<Suspense fallback={<Loader />}><ContactUs urlAdmin={urlAdmin} token={token} /></Suspense>}></Route>
                                <Route exact path="admin:contactUsDetails" element={<Suspense fallback={<Loader />}><ContactUsDetails urlAdmin={urlAdmin} token={token} /></Suspense>}></Route>
                                <Route exact path="admin:nodes" element={<Suspense fallback={<Loader />}><Nodes urlAdmin={urlAdmin} token={token} /></Suspense>}></Route>
                                <Route exact path="admin:pickups" element={<Suspense fallback={<Loader />}><Pickups urlMain={urlMain} token={token} /></Suspense>}></Route>
                                <Route exact path="/admin:pickupsEntry" element={<Suspense fallback={<Loader />}><PickupsEntry urlMain={urlMain} token={token} /></Suspense>}></Route>
                                <Route exact path="admin:offlineDeals" element={<Suspense fallback={<Loader />}><OfflineDeals /></Suspense>}></Route>
                                <Route exact path="admin:area" element={<Suspense fallback={<Loader />}><Area /></Suspense>}></Route>
                                <Route exact path="admin:reason" element={<Suspense fallback={<Loader />}><Reason /></Suspense>}></Route>
                                <Route exact path="admin:promotionImages" element={<Suspense fallback={<Loader />}><Promotions urlAdmin={urlAdmin} token={token} /></Suspense>}></Route>
                                <Route exact path="admin:vehicle" element={<Suspense fallback={<Loader />}><Vehicle /></Suspense>}></Route>
                                {/* ADMIN Route End */}

                                {/* SUPER CRM Route Start  */}
                                <Route exact path="crm:reason" element={<Suspense fallback={<Loader />}><CrmReasons /></Suspense>}></Route>
                                <Route exact path="crm:pickupStatus" element={<Suspense fallback={<Loader />}><PickupStatus /></Suspense>}></Route>
                                <Route exact path="crm:wasteType" element={<Suspense fallback={<Loader />}><WasteType /></Suspense>}></Route>
                                <Route exact path="crm:paymentMode" element={<Suspense fallback={<Loader />}><PaymentMode /></Suspense>}></Route>
                                {/* SUPER CRM Route End  */}

                                {/* HR Route Start */}
                                <Route exact path="hr:departments" element={<Suspense fallback={<Loader />}><Departments /></Suspense>}></Route>
                                <Route exact path="hr:designations" element={<Suspense fallback={<Loader />}><Designations /></Suspense>}></Route>
                                <Route exact path="hr:roles" element={<Suspense fallback={<Loader />}><Roles /></Suspense>}></Route>
                                <Route exact path="hr:employees" element={<Suspense fallback={<Loader />}><Employees urlHR={urlHR} token={token} /></Suspense>}></Route>
                                <Route exact path="hr:employeeDetails" element={<Suspense fallback={<Loader />}><EmployeesDetails /></Suspense>}></Route>
                                <Route exact path="hr:addEmployee" element={<Suspense fallback={<Loader />}><AddEmployee urlHR={urlHR} token={token} /></Suspense>}></Route>
                                <Route exact path="hr:employeePermission" element={<Suspense fallback={<Loader />}><EmployeePermission urlHR={urlHR} token={token} /></Suspense>}></Route>
                                {/* HR Route End */}

                                {/* GOV Route Start */}
                                <Route exact path="gov:properties" element={<Suspense fallback={<Loader />}><GovProperties urlGov={urlGov} token={token} /></Suspense>}></Route>
                                {/* GOV Route End */}

                            </Route>}
                        </Routes>
                    </CrmState>
                }

                {panelType === 'b2g' && (
                    <>
                        <Routes>
                            <Route path="/b2g/login" element={<B2GSignin urlB2G={urlB2G} />} />
                            {isAuthenticated === null && <Route path="/" element={<Loader />} />}
                            {isAuthenticated !== null && (
                                <Route path="/" element={<LayoutB2g />}>
                                    <Route
                                        index
                                        element={
                                            <Suspense fallback={<Loader />}>
                                                <SurveyedPropertiesMap />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="b2g/states"
                                        element={
                                            <Suspense fallback={<Loader />}>
                                                <States />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="b2g/districts"
                                        element={
                                            <Suspense fallback={<Loader />}>
                                                <District />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="b2g/communities"
                                        element={
                                            <Suspense fallback={<Loader />}>
                                                <Communities />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="b2g/wards"
                                        element={
                                            <Suspense fallback={<Loader />}>
                                                <Wards setWardData={setWardData} />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="b2g/geofanceTable"
                                        element={
                                            <Suspense fallback={<Loader />}>
                                                <GeofenceTable urlB2G={urlB2G} setWardData={setWardData} wardData={wardData} />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="b2g/addGeofence"
                                        element={
                                            <Suspense fallback={<Loader />}>
                                                <AddGeofence urlB2G={urlB2G}
                                                    setWardData={setWardData} wardData={wardData}
                                                />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="b2g/viewWardMap"
                                        element={
                                            <Suspense fallback={<Loader />}>
                                                <ViewWardMap urlB2G={urlB2G}
                                                    setWardData={setWardData} wardData={wardData}
                                                />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="b2g/editWardMap"
                                        element={
                                            <Suspense fallback={<Loader />}>
                                                <EditWardMap urlB2G={urlB2G}
                                                    setWardData={setWardData} wardData={wardData}
                                                />
                                            </Suspense>
                                        }
                                    />
                                    {/* <Route
                                        path="b2g/driver"
                                        element={
                                            <Suspense fallback={<Loader />}>
                                                <DriverB2g />
                                            </Suspense>
                                        }
                                    /> */}
                                    <Route
                                        path="b2g/admin"
                                        element={
                                            <Suspense fallback={<Loader />}>
                                                <B2gAdmin />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="b2g/profile"
                                        element={
                                            <Suspense fallback={<Loader />}>
                                                <B2GProfile />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="b2g/communityAdmin"
                                        element={
                                            <Suspense fallback={<Loader />}>
                                                <CommunityAdmin />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="b2g/driverLive"
                                        element={
                                            <Suspense fallback={<Loader />}>
                                                <LiveMap />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="b2g/survey"
                                        element={
                                            <Suspense fallback={<Loader />}>
                                                <SurveyProperties />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="b2g/surveyDetail"
                                        element={
                                            <Suspense fallback={<Loader />}>
                                                <SurveyProperty />
                                            </Suspense>
                                        }
                                    />
                                </Route>
                            )}
                        </Routes>
                    </>
                )}

            </ErrorBoundary>
        </>
    )
}

export default App;

import B2BNavbar from '../../features/navigation_bar/b2bNavigation/b2bNavbar/AdminNavbar'
import B2BSidebar from '../../features/navigation_bar/b2bNavigation/b2bSidebar/AdminSidebar'
import { Outlet } from 'react-router-dom'

const LayoutB2B = ({ urlMain, token, adminRole }) => {
    return (
        <div className='App-b2b'>
            <B2BNavbar urlMain={urlMain} token={token} adminRole={adminRole} />
            {/* <div style={{ display: 'flex' }}> */}
            <B2BSidebar urlMain={urlMain} token={token} adminRole={adminRole} />
            {/* <div style={{ flex: '0 1 auto', width: '400px' }} ></div> */}
            <main className='b2b-main'>
                <Outlet />
            </main>
            {/* </div> */}
        </div>
    );
};
export default LayoutB2B;
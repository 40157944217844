import React from "react";

const MainBreadcrumb = () => {
    return (
        <>
            <li className="breadcrumb-item">
                <span className="breadcrumb-not-active">Main Dashboard</span>
            </li>
        </>
    )
}

export default MainBreadcrumb;
import React from "react";

const Spinner = () => {
    return (
        <>
            <div className="text-center">
                <img className="my-3" src="assets/images/loader-table.gif" alt="spinner-for-table" />
            </div>
        </>
    )
}

export default Spinner;
import React, { useState, useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";

const AdminSidebar = ({ urlMain, token, adminRole }) => {

    const [openTickets, setOpenTickets] = useState([]);
    const location = useLocation();
    const getNotificationApi = async () => {
        try {
            const response = await fetch(`${urlMain}/notification`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })
            const data = await response.json();
            if (data.status === true) {
                setOpenTickets(data.data.OpenTicketes);
            }
        } catch (error) {
            console.error("Error fetching notifications:", error);
        }
    };

    useEffect(() => {
        getNotificationApi();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <nav id="sidebar" className=" bg-left-navabr b2b-sidebar">
                {/* <nav> */}
                {/* <nav className="b2b-sidebar"> */}
                <ul className="navbar-nav scrollBar ms-auto mb-lg-0 d-flex flex-column text-left">
                    {(adminRole >= 1 && adminRole <= 4) || adminRole === 6 ? (<li className="menu-title h6 mt-1 mb-1">Dashboard</li>) : null}
                    {(adminRole >= 1 && adminRole <= 4) && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2" : "font-thin nav-link select2 active"} to="/">
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <span className="h6">
                                <i className="fa-solid fa-house"></i> &nbsp; Dashboard
                            </span>
                        </li>
                    </NavLink>)}
                    {(adminRole === 1 || adminRole === 2) && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2" : "font-thin nav-link select2 active"} to="/dashboard:adminDashboard">
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <span className="h6">
                                <i className="fa-solid fa-house"></i> &nbsp; Admin Dashboard
                            </span>
                        </li>
                    </NavLink>)}
                    {(adminRole === 1 || adminRole === 3) && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2" : "font-thin nav-link select2 active"} to="/dashboard:superCrmDashboard">
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <span className="h6">
                                <i className="fa-solid fa-house"></i> &nbsp; Super CRM Dashboard
                            </span>
                        </li>
                    </NavLink>)}
                    {adminRole === 6 && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2" : "font-thin nav-link select2 active"} to="/dashboard:govermentDashboard">
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <span className="h6">
                                <i className="fa-solid fa-house"></i> &nbsp; Goverment Dashboard
                            </span>
                        </li>
                    </NavLink>)}
                    {(adminRole >= 1 && adminRole <= 4) && (<li className="menu-title h6 mt-1 mb-1">Main</li>)}
                    {(adminRole >= 1 && adminRole <= 4) && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2" : "font-thin nav-link select2 active"} to="/main:users">
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <span className="h6">
                                <i className="fa-solid fa-users-line"></i> &nbsp; Users
                            </span>
                        </li>
                    </NavLink>)}
                    {(adminRole >= 1 && adminRole <= 4) && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2 dropdown" : "font-thin nav-link select2 active dropdown"} to={location.pathname === "/main:allProperties" ? "/main:allProperties" : "/main:dustbins"}>
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <div className="dropdown-toggle text-white" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className="h6">
                                    <i className="fa-solid fa-building"></i> &nbsp; Properties
                                </span>
                            </div>
                            <ul className="dropdown-menu menu-side text-start p-1" aria-labelledby="dropdownMenuButton1">
                                <NavLink className={({ isActive }) => isActive ? "active-transition font-bold dropdown-item chosen" : "font-thin dropdown-item chosen"} to="/main:allProperties">
                                    <li className="link-item">
                                        Properties
                                    </li>
                                </NavLink>
                                <NavLink className={({ isActive }) => isActive ? "active-transition font-bold dropdown-item" : "font-thin dropdown-item"} to="/main:dustbins">
                                    <li className="link-item">
                                        Dustbins
                                    </li>
                                </NavLink>
                            </ul>
                        </li>
                    </NavLink>)}
                    {(adminRole >= 1 && adminRole <= 4) && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2 dropdown" : "font-thin nav-link select2 active dropdown"} to={location.pathname === "/main:currentPickups" ? "/main:currentPickups" : "/main:pickupsHistory"}>
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <div className="dropdown-toggle text-white" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className="h6">
                                    <i className="fa-solid fa-truck-pickup"></i> &nbsp; Pickups
                                </span>
                            </div>
                            <ul className="dropdown-menu menu-side text-start p-1" aria-labelledby="dropdownMenuButton1">
                                <NavLink className={({ isActive }) => isActive ? "active-transition font-bold dropdown-item chosen" : "font-thin dropdown-item chosen"} to="/main:currentPickups">
                                    <li className="link-item">
                                        Current Pickups
                                    </li>
                                </NavLink>
                                <NavLink className={({ isActive }) => isActive ? "active-transition font-bold dropdown-item" : "font-thin dropdown-item"} to="/main:pickupsHistory">
                                    <li className="link-item">
                                        Pickups History
                                    </li>
                                </NavLink>
                            </ul>
                        </li>
                    </NavLink>)}
                    {(adminRole >= 1 && adminRole <= 4) && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2" : "font-thin nav-link select2 active"} to="/main:driverPickups">
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <span className="h6">
                                <i className="fa-solid fa-truck"></i> &nbsp; Driver Pickups
                            </span>
                        </li>
                    </NavLink>)}
                    {(adminRole >= 1 && adminRole <= 4) && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2" : "font-thin nav-link select2 active"} to="/main:categoryList">
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <span className="h6">
                                <i className="fa-sharp fa-solid fa-indian-rupee-sign"></i> &nbsp; Incentive List
                            </span>
                        </li>
                    </NavLink>)}
                    {(adminRole >= 1 && adminRole <= 4) && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2" : "font-thin nav-link select2 active"} to="/main:transactionsHistory">
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <span className="h6">
                                <i className="fa-solid fa-wallet"></i> &nbsp; Transactions History
                            </span>
                        </li>
                    </NavLink>)}
                    {(adminRole >= 1 && adminRole <= 4) && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2" : "font-thin nav-link select2 active"} to="/main:holidays">
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <span className="h6">
                                <i className="fa-solid fa-calendar-days"></i> &nbsp; Holidays
                            </span>
                        </li>
                    </NavLink>)}
                    {(adminRole === 1 || adminRole === 3 || adminRole === 4) && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2 dropdown" : "font-thin nav-link select2 active dropdown"} to={location.pathname === "/main:openQueries" ? "/main:openQueries" : "/main:queries"}>
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <div className="dropdown-toggle text-white" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className="h6">
                                    <i className="fa-sharp fa-solid fa-comments"></i> Compliant {openTickets === 0 ? <span></span> : <span className="badge rounded-circle badge-danger text-right">{openTickets}</span>}
                                </span>
                            </div>
                            <ul className="dropdown-menu menu-side text-start p-1" aria-labelledby="dropdownMenuButton1">
                                <NavLink className={({ isActive }) => isActive ? "active-transition font-bold dropdown-item chosen" : "font-thin dropdown-item chosen"} to="/main:openQueries">
                                    <li className="link-item">
                                        Chats
                                    </li>
                                </NavLink>
                                <NavLink className={({ isActive }) => isActive ? "active-transition font-bold dropdown-item chosen" : "font-thin dropdown-item chosen"} to="/main:queries">
                                    <li className="link-item">
                                        Queries
                                    </li>
                                </NavLink>
                            </ul>
                        </li>
                    </NavLink>)}
                    {adminRole === 1 && (<li className="menu-title h6 mt-1 mb-1">Super Admin</li>)}
                    {adminRole === 1 && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2" : "font-thin nav-link select2 active"} to="/superAdmin:banks">
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <span className="h6">
                                <i className="fa-solid fa-building-columns"></i> &nbsp; Banks
                            </span>
                        </li>
                    </NavLink>)}
                    {adminRole === 1 && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2" : "font-thin nav-link select2 active"} to="/superAdmin:usersBank">
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <span className="h6">
                                <i className="fa-solid fa-building-columns"></i> &nbsp; Users Bank
                            </span>
                        </li>
                    </NavLink>)}
                    {adminRole === 1 && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2" : "font-thin nav-link select2 active"} to="/superAdmin:usersUpi">
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <span className="h6">
                                <img width="20" height="20" className="filter-invert" src="https://img.icons8.com/ios/50/bhim.png" alt="bhim" /> &nbsp; Users UPI
                            </span>
                        </li>
                    </NavLink>)}
                    {adminRole === 1 && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2" : "font-thin nav-link select2 active"} to="/superAdmin:fundSource">
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <span className="h6">
                                <i className="fa-brands fa-sourcetree"></i> &nbsp; Fund Source
                            </span>
                        </li>
                    </NavLink>)}
                    {adminRole === 1 && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2" : "font-thin nav-link select2 active"} to="/superAdmin:crmUsers">
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <span className="h6">
                                <i className="fa-solid fa-users"></i> &nbsp; Crm User
                            </span>
                        </li>
                    </NavLink>)}
                    {adminRole === 1 && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2" : "font-thin nav-link select2 active"} to="/superAdmin:logs">
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <span className="h6">
                                <i className="fa-brands fa-slack"></i> &nbsp; Logs
                            </span>
                        </li>
                    </NavLink>)}
                    {adminRole === 1 && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2" : "font-thin nav-link select2 active"} to="/superAdmin:deletAccount">
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <span className="h6">
                                <i className="fa-solid fa-trash"></i> &nbsp; Delete Account
                            </span>
                        </li>
                    </NavLink>)}
                    {(adminRole === 1 || adminRole === 2) && (<li className="menu-title h6 mt-1 mb-1">Admin</li>)}
                    {(adminRole === 1 || adminRole === 2) && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2" : "font-thin nav-link select2 active"} to="/admin:drivers">
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <span className="h6">
                                <i className="fa-solid fa-truck"></i> &nbsp; Drivers
                            </span>
                        </li>
                    </NavLink>)}
                    {(adminRole === 1 || adminRole === 2) && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2" : "font-thin nav-link select2 active"} to="/admin:blogs">
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <span className="h6">
                                <i className="fa-solid fa-blog"></i> &nbsp; Blogs
                            </span>
                        </li>
                    </NavLink>)}
                    {(adminRole === 1 || adminRole === 2) && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2" : "font-thin nav-link select2 active"} to="/admin:contactUs">
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <span className="h6">
                                <i className="fa-solid fa-address-card"></i> &nbsp; Contact Us
                            </span>
                        </li>
                    </NavLink>)}
                    {(adminRole === 1 || adminRole === 2) && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2 dropdown" : "font-thin nav-link select2 active dropdown"} to={location.pathname === "/admin:nodes" ? "/admin:nodes" : "/admin:pickups"}>
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <div className="dropdown-toggle text-white" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className="h6">
                                    <i className="fa-solid fa-circle-nodes"></i> &nbsp; Godown SKU
                                </span>
                            </div>
                            <ul className="dropdown-menu menu-side text-start p-1" aria-labelledby="dropdownMenuButton1">
                                <NavLink className={({ isActive }) => isActive ? "active-transition font-bold dropdown-item chosen" : "font-thin dropdown-item chosen"} to="/admin:nodes">
                                    <li className="link-item">
                                        Nodes
                                    </li>
                                </NavLink>
                                <NavLink className={({ isActive }) => isActive ? "active-transition font-bold dropdown-item chosen" : "font-thin dropdown-item chosen"} to="/admin:pickups">
                                    <li className="link-item">
                                        Pickups
                                    </li>
                                </NavLink>
                                <NavLink className={({ isActive }) => isActive ? "active-transition font-bold dropdown-item chosen" : "font-thin dropdown-item chosen"} to="/admin:pickupsEntry">
                                    <li className="link-item">
                                        Pickups Entry
                                    </li>
                                </NavLink>
                            </ul>
                        </li>
                    </NavLink>)}
                    {(adminRole === 1 || adminRole === 2) && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2" : "font-thin nav-link select2 active"} to="/admin:offlineDeals">
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <span className="h6">
                                <i className="fa-brands fa-ideal"></i> &nbsp; Offline Deals
                            </span>
                        </li>
                    </NavLink>)}
                    {(adminRole === 1 || adminRole === 2) && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2 dropdown" : "font-thin nav-link select2 active dropdown"} to={location.pathname === "/admin:area" ? "/admin:area" : location.pathname === "/admin:reason" ? "/admin:reason" : location.pathname === "/admin:promotionImages" ? "/admin:promotionImages" : "/admin:vehicle"}>
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <div className="dropdown-toggle text-white" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className="h6">
                                    <i className="fa-sharp fa-solid fa-circle-info"></i> &nbsp; Others
                                </span>
                            </div>
                            <ul className="dropdown-menu menu-side text-start p-1" aria-labelledby="dropdownMenuButton1">
                                <NavLink className={({ isActive }) => isActive ? "active-transition font-bold dropdown-item chosen" : "font-thin dropdown-item chosen"} to="/admin:area">
                                    <li className="link-item">
                                        Areas
                                    </li>
                                </NavLink>
                                <NavLink className={({ isActive }) => isActive ? "active-transition font-bold dropdown-item chosen" : "font-thin dropdown-item chosen"} to="/admin:reason">
                                    <li className="link-item">
                                        Reasons
                                    </li>
                                </NavLink>
                                <NavLink className={({ isActive }) => isActive ? "active-transition font-bold dropdown-item chosen" : "font-thin dropdown-item chosen"} to="/admin:promotionImages">
                                    <li className="link-item">
                                        Promotion Images
                                    </li>
                                </NavLink>
                                <NavLink className={({ isActive }) => isActive ? "active-transition font-bold dropdown-item chosen" : "font-thin dropdown-item chosen"} to="/admin:vehicle">
                                    <li className="link-item">
                                        Vehicles
                                    </li>
                                </NavLink>
                            </ul>
                        </li>
                    </NavLink>)}
                    {(adminRole === 1 || adminRole === 3) && (<li className="menu-title h6 mt-1 mb-1">Super CRM</li>)}
                    {(adminRole === 1 || adminRole === 3) && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2 dropdown" : "font-thin nav-link select2 active dropdown"} to={location.pathname === "/crm:reason" ? "/crm:reason" : location.pathname === "/crm:pickupStatus" ? "/crm:pickupStatus" : location.pathname === "/crm:wasteType" ? "/crm:wasteType" : "/crm:paymentMode"}>
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <div className="dropdown-toggle text-white" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className="h6">
                                    <i className="fa-sharp fa-solid fa-circle-info"></i> &nbsp; Others
                                </span>
                            </div>
                            <ul className="dropdown-menu menu-side text-start p-1" aria-labelledby="dropdownMenuButton1">
                                <NavLink className={({ isActive }) => isActive ? "active-transition font-bold dropdown-item chosen" : "font-thin dropdown-item chosen"} to="/crm:reason">
                                    <li className="link-item">
                                        Reasons
                                    </li>
                                </NavLink>
                                <NavLink className={({ isActive }) => isActive ? "active-transition font-bold dropdown-item chosen" : "font-thin dropdown-item chosen"} to="/crm:pickupStatus">
                                    <li className="link-item">
                                        Pickup Status
                                    </li>
                                </NavLink>
                                <NavLink className={({ isActive }) => isActive ? "active-transition font-bold dropdown-item chosen" : "font-thin dropdown-item chosen"} to="/crm:wasteType">
                                    <li className="link-item">
                                        Waste Type
                                    </li>
                                </NavLink>
                                <NavLink className={({ isActive }) => isActive ? "active-transition font-bold dropdown-item chosen" : "font-thin dropdown-item chosen"} to="/crm:paymentMode">
                                    <li className="link-item">
                                        Payment Mode
                                    </li>
                                </NavLink>
                            </ul>
                        </li>
                    </NavLink>)}
                    {(adminRole === 1 || adminRole === 5) && (<li className="menu-title h6 mt-1 mb-1">HR</li>)}
                    {(adminRole === 1 || adminRole === 5) && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2" : "font-thin nav-link select2 active"} to="/hr:departments">
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <span className="h6">
                                <i className="fa-solid fa-building-user"></i> &nbsp; Departments
                            </span>
                        </li>
                    </NavLink>)}
                    {(adminRole === 1 || adminRole === 5) && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2" : "font-thin nav-link select2 active"} to="/hr:designations">
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <span className="h6">
                                <i className="fa-brands fa-critical-adminRole"></i> &nbsp; Designations
                            </span>
                        </li>
                    </NavLink>)}
                    {(adminRole === 1 || adminRole === 5) && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2" : "font-thin nav-link select2 active"} to="/hr:roles">
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <span className="h6">
                                <i className="fa-solid fa-list-check"></i> &nbsp; Roles
                            </span>
                        </li>
                    </NavLink>)}
                    {(adminRole === 1 || adminRole === 5) && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2" : "font-thin nav-link select2 active"} to="/hr:employees">
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <span className="h6">
                                <i className="fa-solid fa-users"></i> &nbsp; Employees
                            </span>
                        </li>
                    </NavLink>)}
                    {adminRole === 6 && (<li className="menu-title h6 mt-1 mb-1">Goverment</li>)}
                    {adminRole === 6 && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2" : "font-thin nav-link select2 active"} to="/gov:properties">
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <span className="h6">
                                <i className="fa-solid fa-building"></i> &nbsp; Properties
                            </span>
                        </li>
                    </NavLink>)}
                    {/* {adminRole=== 8 && (<NavLink className={({ isActive }) => isActive ? "active-transition font-bold dropdown-item chosen" : "font-thin dropdown-item chosen"} to="/main:allProperties">
                        <li className="link-item">
                            Properties
                        </li>
                    </NavLink>)} */}
                    {adminRole === 8 && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', width: '100%', height: '70px', marginBottom: '10px' }}>
                        <img src="assets/images/waste_up.png" alt="waste up logo" style={{ width: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                    </div>}
                    {adminRole === 8 && (<NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2" : "font-thin nav-link select2 active"} to="/main:allProperties">
                        <li className="nav-item select mt-1 mb-1 ms-3">
                            <span className="h6">
                                <i className="fa-solid fa-building"></i> &nbsp; Properties
                            </span>
                        </li>
                    </NavLink>
                    )}
                </ul>
            </nav>
        </>
    )
}

export default AdminSidebar;
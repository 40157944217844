import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const AdminUpdateInformation = ({ urlGov, token, refOpenInformation }) => {

    const [updateInformations, setUpdateInformations] = useState({ name: "", number: "" });
    const [loadingButton, setLoadingButton] = useState(false);
    const refCloseInformation = useRef(null);
    const navigate = useNavigate();

    const handleUpdateInformation = async (e) => {
        e.preventDefault();
        try {
            setLoadingButton(true);
            const response = await fetch(`${urlGov}/updateInfomation`, {
                method: "POST",
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    name: updateInformations.name,
                    number: updateInformations.number
                })
            })
            const data = await response.json();
            setLoadingButton(false);

            if (data.status === true) {
                swal("Success", data.message, "success").then((ok) => {
                    if (ok) {
                        navigate("/govermentDashboard");
                        window.location.reload();
                    }
                })
            } else {
                swal("Error", data.message, "error");
            }
        } catch (error) {
            console.error("Error updating information:", error);
            setLoadingButton(false);
        }
    };


    const changeUpdateInformation = (e) => setUpdateInformations({ ...updateInformations, [e.target.name]: e.target.value });

    return (
        <>
            <button ref={refOpenInformation} type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#updateInformation"></button>

            <div className="modal fade" id="updateInformation" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="updateInformationLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="updateInformationLabel">Update Information</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form className="my-3">
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        value={updateInformations.name}
                                        onChange={changeUpdateInformation}
                                        autoComplete="off"
                                        required
                                    />
                                </div>
                                <div className="mt-3 mb-3">
                                    <label htmlFor="number" className="form-label">Number</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="number"
                                        name="number"
                                        value={updateInformations.number}
                                        onChange={changeUpdateInformation}
                                        autoComplete="off"
                                        required
                                    />
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-dark" data-bs-dismiss="modal" ref={refCloseInformation}>Close</button>
                            <button type="button" className="btn btn-ff9b44" onClick={handleUpdateInformation} disabled={updateInformations.name.length < 1 || updateInformations.number.length < 1}>{loadingButton ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminUpdateInformation;
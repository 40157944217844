import B2GNavbar from '../../features/navigation_bar/b2gNavigation/b2gNavbar';
import B2GSidebar from '../../features/navigation_bar/b2gNavigation/b2gSideBar'
import { Outlet } from 'react-router-dom';

const LayoutB2g = () => {
    return (
        <div className='App-b2g'>
            <B2GNavbar />
            <B2GSidebar />
            <main className='main-b2g' >
                <Outlet />
            </main>

        </div>
    );
};

export default LayoutB2g

import { useState, useEffect } from "react";

const useLoadingPage = () => {

    const [loadingPage, setLoadingPage] = useState(false);

    useEffect(() => {
        setLoadingPage(true);
        const timeoutId = setTimeout(() => {
            setLoadingPage(false);
        }, 2000);
        return () => clearTimeout(timeoutId);
    }, []);

    return loadingPage;
}

export default useLoadingPage;
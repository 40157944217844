import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Avatar from "react-avatar";
import useLoadingPage from "../../../../hooks/useLoadingPage";
import SpinnerForPage from "../../../../components/spinner/SpinnerForPage";
import SpinnerForTable from "../../../../components/spinner/SpinnerForTable";
import MainBreadcrumb from "../../../../components/breadcrumb/MainBreadcrumb";

const TransactionsHistory = ({ urlMain, token, transactionsHistoryData, setTransactionsHistoryData, transactionsHistorySearch, setTransactionsHistorySearch, page, setPage, }) => {

    const loadingPage = useLoadingPage();
    const [loadingTable, setLoadingTable] = useState(true);
    const navigate = useNavigate();

    const firstPage = () => setPage(1);
    const pageMin = () => setPage((prevPage) => Math.max(prevPage - 1));
    const pageAdd = () => setPage((prevPage) => Math.min(prevPage + 1));

    const getTransactionsHistoryApi = async () => {
        try {
            setLoadingTable(true);
            const response = await fetch(`${urlMain}/transaction?page=${page}&status=${transactionsHistorySearch.status}&payout_id=${transactionsHistorySearch.payout}&ecowrap_id=${transactionsHistorySearch.ecowrap}&challan_number=${transactionsHistorySearch.challan}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })
            const data = await response.json();
            if (data.status === true) {
                setTransactionsHistoryData(data.data);
            }
            setLoadingTable(false);
        } catch (error) {
            console.error("Error fetching transactions history:", error);
            setLoadingTable(false);
        }
    };

    const changeSearch = (e) => setTransactionsHistorySearch({ ...transactionsHistorySearch, [e.target.name]: e.target.value });
    const handleReset = () => window.location.reload();

    useEffect(() => {
        document.title = "Transactions History - Admin Panel | CRM";
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        getTransactionsHistoryApi();
        // eslint-disable-next-line
    }, [page]);

    return (
        <>
            {
                loadingPage ? <SpinnerForPage /> :
                    <div className="container-fluid mt-5">
                        <div className="row">
                            <div className="col-md-6 text-left">
                                <h3 className="page-title">Transactions History</h3>
                                <ul className="breadcrumb">
                                    <MainBreadcrumb />
                                    <li className="breadcrumb-item">
                                        <span className="breadcrumb-active">Transactions History</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row mt-4">
                            {
                                transactionsHistoryData?.cardData?.map((element, index) => {
                                    return (
                                        <div className="col-md-3" key={index}>
                                            <div className="card">
                                                <div className="card-body text-center status-info">
                                                    <h6>{element.transaction_status}</h6>
                                                    <h4>{element.total_transactions}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className="col-md-3">
                                <div className="card">
                                    <div className="card-body text-center status-info">
                                        <h6>Total Transactions</h6>
                                        <h4>{transactionsHistoryData.totalData}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2">
                                <div className="form-floating">
                                    <select className="form-select" id="status" name="status" value={transactionsHistorySearch.status} onChange={changeSearch}>
                                        <option value="">---Select---</option>
                                        <option value="PENDING">Pending</option>
                                        <option value="SUCCESS">Success</option>
                                        <option value="FAILED">Failed</option>
                                        <option value="REJECTED">Rejected</option>
                                        <option value="REVERSED">Reversed</option>
                                        <option value="RECEIVED">Received</option>
                                    </select>
                                    <label htmlFor="status" className="mb-3">Status</label>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-floating">
                                    <input type="text" className="form-control" id="payout" name="payout" placeholder="Search" autoComplete="off" value={transactionsHistorySearch.payout} onChange={changeSearch} />
                                    <label htmlFor="payout" className="mb-3">Payout ID</label>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-floating">
                                    <input type="text" className="form-control" id="ecowrap" name="ecowrap" placeholder="Search" autoComplete="off" value={transactionsHistorySearch.ecowrap} onChange={changeSearch} />
                                    <label htmlFor="ecowrap" className="mb-3">Ecowrap ID</label>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-floating">
                                    <input type="text" className="form-control" id="challan" name="challan" placeholder="Search" autoComplete="off" value={transactionsHistorySearch.challan} onChange={changeSearch} />
                                    <label htmlFor="challan" className="mb-3">Challan No.</label>
                                </div>
                            </div>
                            <div className="col-md-1 pt-1">
                                <button type="button" className="btn btn-55ce63 btn-lg me-1" onClick={() => {
                                    getTransactionsHistoryApi();
                                    setPage(1);
                                }}>
                                    Search
                                </button>
                            </div>
                            <div className="col-md-1 pt-1">
                                <button type="reset" className="btn btn-55ce63 btn-lg" onClick={handleReset}>
                                    Reset
                                </button>
                            </div>
                        </div>
                        <div className="card card-dark mt-4">
                            {
                                loadingTable ? <SpinnerForTable /> : transactionsHistoryData?.data?.length === 0 ? (
                                    <div className="text-center mt-5 mb-5 fw-bold">No transactions history found at the moment!</div>
                                ) : (
                                    <div className="card-block table-border-style">
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr className="text-left">
                                                        <th scope="col"></th>
                                                        <th scope="col">Property Name</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Driver Name</th>
                                                        <th scope="col">T Mode</th>
                                                        <th scope="col">Ch_No</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Initiated At</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        transactionsHistoryData?.data?.map((element, index) => {
                                                            return (
                                                                <tr className="text-left fw-normal cp" key={index} onClick={() => {
                                                                    navigate("/main:transactionsHistoryDetails", { state: element })
                                                                }}>
                                                                    <td><Avatar round={true} size="35" name={element.PropertyName} /></td>
                                                                    <td className="fw-bold">{element.PropertyName}<div className="fw-lighter text-muted">{element.address.split(",")[element.address.split(",").length - 4]}</div></td>
                                                                    <td>{element.ContactPerson}<div className="fw-lighter text-muted">{element.ContactNumber}</div></td>
                                                                    <td>{element.DriverName}<div className="fw-lighter text-muted">{element.DriverNumber}</div></td>
                                                                    <td className="text-uppercase">{element.transfer_mode === 'cash' ? 'Due' : element.transfer_mode}</td>
                                                                    <td>{element.challan_number}</td>
                                                                    <td>₹ {element.paid_amount}</td>
                                                                    {element.transaction_status === "PENDING" && <td> <span className="badge bg-warning">Pending</span></td>}
                                                                    {element.transaction_status === "SUCCESS" && <td>  <span className="badge bg-success">Success</span> </td>}
                                                                    {element.transaction_status === "FAILED" &&
                                                                        <td><span className="badge bg-danger">Failed</span></td>}
                                                                    {element.transaction_status === "REJECTED" &&
                                                                        <td><span className="badge bg-danger">Rejected</span></td>}
                                                                    {element.transaction_status === "REVERSED" &&
                                                                        <td><span className="badge bg-danger">REVERSED</span></td>}
                                                                    {element.transaction_status === "REVERSED" &&
                                                                        <td><span className="badge bg-danger">REVERSED</span></td>}
                                                                    {element.transaction_status === "RECEIVED" &&
                                                                        <td><span className="badge bg-danger">Received</span></td>}
                                                                    <td>{moment(element.create_time).format("DD/MM/YYYY | hh:mm A").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" })}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="container mt-3 mb-3" id="paginationnumbers">
                                            <div className="pagination" id="paginationnumbers">
                                                <div className="page-item text-left">
                                                    <button className="page-link" disabled={page <= 1} onClick={firstPage}>
                                                        1
                                                    </button>
                                                </div>
                                                <div className="page-item text-left">
                                                    <button className="page-link" disabled={page <= 1} onClick={pageMin}>
                                                        &laquo; Previous
                                                    </button>
                                                </div>
                                                <div className="page-item">
                                                    <div className="page-link">
                                                        {page} of {transactionsHistoryData?.totalPages}
                                                    </div>
                                                </div>
                                                <div className="page-item text-left">
                                                    <button className="page-link" disabled={page === transactionsHistoryData?.totalPages} onClick={pageAdd}>
                                                        Next &raquo;
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
            }
        </>
    )
}

export default TransactionsHistory;
import React, { useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import { ReactComponent as Sun } from "./sun.svg";
import { ReactComponent as Moon } from "./moon.svg";
import "./darkMode.css";

const DarkMode = () => {

    const setDarkMode = () => {
        document.body.setAttribute("data-theme", "dark");
        secureLocalStorage.setItem("selectedTheme", "dark");
    };

    const setLightMode = () => {
        document.body.setAttribute("data-theme", "light");
        secureLocalStorage.setItem("selectedTheme", "light");
    };

    const toggleTheme = (e) => {
        if (e.target.checked) setDarkMode();
        else setLightMode();
    };

    useEffect(() => {
        const selectedTheme = secureLocalStorage.getItem("selectedTheme");
        if (selectedTheme === "dark") setDarkMode();
    }, []);

    return (
        <>
            <div className="dark-mode">
                <input type="checkbox" className="dark-mode-input" id="darkmode-toggle" onChange={toggleTheme} defaultChecked={secureLocalStorage.getItem("selectedTheme") === "dark"} />
                <label htmlFor="darkmode-toggle" className="dark-mode-label">
                    <Sun />
                    <Moon />
                </label>
            </div>
        </>
    )
}

export default DarkMode;